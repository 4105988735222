import React, { useState, useRef, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import axios from "axios";
import { baseURL } from "../../../service/API.js";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
// eslint-disable-next-line no-restricted-imports
import Switch from "@material-ui/core/Switch";
import { Link } from "react-router-dom";
import "../../component.css";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import moment from 'moment'
import BootstrapTable from "react-bootstrap-table-next";
// import { checkForbiddenSymbols } from "../../../utils/regex";
import { useLocation } from "react-router-dom";
import checkForbiddenSymbols from "../../../utils/regex.js";
import { use } from "react";

export const CreateDeliveryNote = () => {
  const location = useLocation();
  const { deliveryNote } = location.state || {};
  const intl = useIntl();
  const suhbeader = useSubheader();
  const { authToken } = useSelector((state) => state.auth);
  suhbeader.setTitle(
    intl.formatMessage({ id: "113168", defaultMessage: "Transfer Order" })
  );
  const history = useHistory();
  const [printer_zone_name, setprinter_zone_name] = useState();
  const [printer_zone_name_en, setprinter_zone_name_en] = useState();
  const [state, setState] = useState(true);
  const [shopList, setShopList] = useState([]);
  const [itemList, setitemList] = useState([]);
  const [itemForSent, setItemForSent] = useState([]);
  const [shop, setShop] = useState(null);
  const [shopShowOrigin, setShopShowOrigin] = useState(null);
  const [shopShowDestination, setShopShowDestination] = useState(null);
  const [branchOrigin, setBranchOrigin] = useState(null);
  const [branchDestination, setBranchDestination] = useState(null);
  const [item, setItem] = useState(null);
  const [listData, setListData] = useState([]);
  const [qty, setQty] = useState(null);
  const [isAdd, setIsAdd] = useState(false);
  const [maxIndex, setMaxIndex] = useState(0);
  const [description, setDescription] = useState('')
  const [regexError, setRegexError] = useState("");
  const [unitList, setUnitList] = useState([]);
  const [unit, setUnit] = useState(null);
  const [unitId, setUnitId] = useState(null);
  const [unitType, setUnitType] = useState(null);

  const gettingItem = async (branch_id) => {
    let allData = [];
    await axios({
      method: "get",
      url: `${baseURL}/inventory/listBranch/${branch_id}?page=1&limit=10`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    }).then((res) => {
      console.log(res.data.data);
      setitemList(res.data.data);
    });
  };

  const handleChangeShopOrigin = (event, values) => {
    console.log("Origin Branch ->", values);
    if (values) {
      setShopShowOrigin(values);
      setShop(values.id);
      setBranchOrigin(values.posBranch.id);
      gettingItem(values.posBranch.id);
    } else {
      setShopShowOrigin(null);
      setBranchOrigin(null);
    }
  };
  const handleChangeShopDestination = (event, values) => {
    console.log("Destination Branch ->", values);
    if (values) {
      setShopShowDestination(values);
      setBranchDestination(values.posBranch.id);
    } else {
      setShopShowDestination(null);
      setBranchDestination(null);
    }
  };

  const filteredShopListForOrigin = shopList.filter(shop => shop.posBranch.id !== branchDestination);
  const filteredShopListForDestination = shopList.filter(shop => shop.posBranch.id !== branchOrigin);

  const handleChangeItem = (event, values) => {
    console.log(event, values);
    if (values) {
      setItem(values);
      setUnit(null);
      setUnitType(null);
      setUnitList([values.major_unit_name, values.minor_unit_name]);
    } else {
      setItem(null);
      setUnit(null);
      setUnitType(null);
      setUnitList([]);
      setUnitId(null)
    }
  };

  const handleChangeUnit = (event, values) => {
    console.log(event, values);
    if (values) {
      console.log("values ->", values)
      setUnit(values);
      if (values === item.major_unit_name) {
        setUnitId(item.main_unit_id);
        setUnitType("major");
      } else if (values === item.minor_unit_name) {
        setUnitId(item.sub_unit_id);
        setUnitType("minor");
      }
    } else {
      setUnit(null);
      setUnitType(null);
      setUnitId(null)
    }
  };

  useEffect(() => {
    gettingShop();
  }, []);

  useEffect(() => {
    if (deliveryNote) {
      console.log("deliveryNote ->", deliveryNote);
      setShopShowOrigin({ posBranch: { name: deliveryNote.send_branch_name } });
      setShopShowDestination({ posBranch: { name: deliveryNote.received_branch_name } });
      setBranchOrigin(deliveryNote.send_branch_id);
      setShop(deliveryNote.send_branch_id);
      gettingItem(deliveryNote.send_branch_id)
      setBranchDestination(deliveryNote.received_branch_id);
      setDescription(deliveryNote.description);
      setListData(deliveryNote.deliveryNoteDetail.map(item => ({
        item_id: item.item_id,
        name: item.inventory.bom_material.name,
        qty: item.qty,
        unit: item.unit,
        unit_id: item.unit_id,
        unit_type: item.unit_type,
        material_id: item.material_id
      })));
    }
  }, [deliveryNote]);

  const columns = [
    {
      dataField: "no",
      text: "No.",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
    },

    {
      dataField: "name",
      text: "name",
    },

    {
      dataField: "qty",
      text: "quantity",
    },
    {
      dataField: "unit",
      text: "unit",
    },
    {
      text: intl.formatMessage({ id: "107008", defaultMessage: "Action" }),
      dataField: "id",
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => deleteItem(rowIndex)}
            >
              {intl.formatMessage({ id: "107011", defaultMessage: "delete" })}
            </button>
          </div>
        );
      },
    },
  ];

  const deleteItem = async (rowIndex) => {
    const array = [...listData];
    const removedItem = array[rowIndex];
    array.splice(rowIndex, 1);
    setListData(array);
  
    const fullItem = itemForSent.find((material) => material.id === removedItem.item_id);
  
    if (fullItem) {
      setitemList((prev) => [...prev, fullItem]);
      setItemForSent((prev) => prev.filter((material) => material.id !== removedItem.item_id));
    }
  };


  const gettingShop = async () => {
    let allData = [];
    await axios({
      method: "get",
      url: `${baseURL}/shopGroup`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    }).then((res) => {
      console.log("shopList ->", res.data.data);
      setShopList(res.data.data);
    });
  };

  
  

  const handleChangeSwitch = () => {
    setState(!state);
  };
  const handleDescriptionChange = (event) => {
    const value = event.target.value;
    if (!checkForbiddenSymbols(value)) {
      setRegexError("");
      setDescription(value);
    } else {
      setRegexError(`เครื่องหมาย = < > ! ; ' " & |  ไม่ได้รับอนุญาตให้ใช้`);
    }
  };

  const handleAdd = async () => {
    /*setListData(listData => [...listData, {
      index: maxIndex+1,
      item_id: item.id,
      name: item.name,
      qty: qty,
    }])*/
    if (!shop) {
      await AlertError('please insert shop')
    } /*else if (!description || description == '') {
      await AlertError('please insert description')
    }*/ else if (!item) {
      await AlertError('please insert item')
    } else if (!qty || qty < 1) {
      await AlertError('please insert correct quantity')
    } else if (!unit) {
      await AlertError('please insert unit ')
    } else {
      let temp = listData
      console.log("temp ->", temp)
      temp.push({
        index: maxIndex + 1,
        item_id: item.id,
        name: item.name,
        qty: qty,
        unit: unit,
        unit_type: unitType,
        unit_id: unitId,
        material_id: item.material_id
      })
      setListData(temp)
      setMaxIndex(maxIndex + 1)
      setQty(0)
      setIsAdd(true)
      setItem(null)
      setUnit(null)
      setUnitType(null)
      setUnitList([])
      setUnitId(null)
      // setDescription('')
      //setShop(null)
      const updatedItemList = itemList.filter((material) => material.id !== item.id);
    setitemList(updatedItemList);
    setItemForSent((prev) => [...prev, item]);
    }

  }

  const AlertError = (message) => {
    Swal.fire({
      title: intl.formatMessage({
        id: "114000",
        defaultMessage: "Unable to Create Transfer Order.",
      }),
      text: message,
      icon: "error"
    });
  };
  
  const handleSend = async () => {
    if (shop === undefined || shop === null) {
      return AlertError(intl.formatMessage({
        id: "114001",
        defaultMessage: "Please check your data and try again."
      }));
    }
    let get_shop_id = localStorage.getItem("shop_id");
    let get_branch_id = localStorage.getItem("branch_id");
    let now = new Date();
    var events = {
      order_date: moment(new Date()).format('YYYY-MM-DD HH:mm'),
      description: description === '' ? 'none' : description,
      shop_id: get_shop_id,
      branch_id: get_branch_id,
      list: listData,
      send_shop_id: get_shop_id,
      send_branch_id: branchOrigin,
      received_branch_id: branchDestination,
    };
  
    let apiUrls = `${baseURL}/deliveryNote`;
    axios({
      method: "post",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      data: events,
    })
      .then(async (res) => {
        Swal.fire("Save !", "Save Create Transfer Order success", "success");
        history.push({
          pathname: '/TransferOrder',
        });
      })
      .catch((err) => {
        console.log("error -> ", err);
        let errorMessage = intl.formatMessage({
          id: "114001",
          defaultMessage: "Unable to create transfer order"
        });
        if (err.response && err.response.status === 500 && err.response.data.message === "Not enough quantity in inventory") {
          errorMessage = intl.formatMessage({
            id: "114002",
            defaultMessage: "Not enough quantity in inventory"
          });
        }
        AlertError(errorMessage);
      });
  };

  return (
    <div>
      <Card>
        <CardHeader
          title={intl.formatMessage({
            id: "113163",
            defaultMessage: "Create Transfer Order",
          })}
        ></CardHeader>
        <CardBody>
          <div className="container">
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                <span style={{ color: "red" }}>*</span>
                {intl.formatMessage({
                  id: "113567",
                  defaultMessage: "Origin Branch",
                })}
                :{" "}
              </p>
              <div>
                <Autocomplete
                  id="zone-printer-origin"
                  disabled={isAdd || deliveryNote}
                  options={filteredShopListForOrigin}
                  getOptionLabel={(option) =>
                    option.posBranch ? option.posBranch.name : "ไม่มีชื่อ"
                  }
                  value={shopShowOrigin}
                  onChange={handleChangeShopOrigin}
                  style={{
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder={intl.formatMessage({ id: "113580", defaultMessage: "Please select origin branch", })}
                    />
                  )}
                />
              </div>
            </div>
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                <span style={{ color: "red" }}>*</span>
                {intl.formatMessage({
                  id: "113568",
                  defaultMessage: "Destination Branch",
                })}
                :{" "}
              </p>
              <div>
                <Autocomplete
                  id="zone-printer-destination"
                  disabled={isAdd}
                  options={filteredShopListForDestination}
                  getOptionLabel={(option) =>
                    option.posBranch ? option.posBranch.name : "ไม่มีชื่อ"
                  }
                  value={shopShowDestination}
                  onChange={handleChangeShopDestination}
                  style={{
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder={intl.formatMessage({ id: "113581", defaultMessage: "Please select destination branch", })}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="container">

            <div className="form-inline" style={{ marginBottom: "25px" }}>

              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                <span style={{ color: "red" }}>
                  *
                </span>
                {intl.formatMessage({ id: "113169", defaultMessage: "Description" })}:{" "}
              </p>
              <div className="flex-col justify-content-end">
                <input
                  name="Description"
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  value={description}
                  onChange={handleDescriptionChange}
                />
                {regexError && <p style={{ color: "red", marginTop: "5px" }}>{regexError}</p>}
              </div>
            </div>
          </div>
          <hr></hr>

          <div className="container">
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                <span style={{ color: "red" }}>*</span>
                {intl.formatMessage({
                  id: "113202",
                  defaultMessage: "Material",
                })}
                :{" "}
              </p>
              <div>
                <Autocomplete
                  id="zone-printer-item"
                  options={itemList}
                  value={item}
                  getOptionLabel={(option) =>
                    option.name ? option.name : "ไม่มีชื่อ"
                  }
                  onChange={handleChangeItem}
                  style={{
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder={intl.formatMessage({ id: "113582", defaultMessage: "Please select material", })}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                <span style={{ color: "red" }}>*</span>
                {/* {intl.formatMessage({
                  id: "113166",
                  defaultMessage: "Item",
                })} */} Unit
                :{" "}
              </p>
              <div>
                <Autocomplete
                  id="unit"
                  options={unitList}
                  value={unit}
                  getOptionLabel={(option) => option}
                  onChange={handleChangeUnit}
                  style={{
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder={intl.formatMessage({ id: "113539", defaultMessage: "Please select unit", })}
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <div className="container">
            <div
              className="form-inline"
              style={{
                marginBottom: "25px",
              }}
            >
              <p
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              >
                <span style={{ color: "red" }}>*</span>
                {intl.formatMessage({
                  id: "113165",
                  defaultMessage: "Amount",
                })}
                :{" "}
              </p>
              <input
                value={qty}
                type="number"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                onChange={(e) =>
                  setQty(e.target.value)
                }
              />
              <button
                className="ml-2 btn btn-primary"
                onClick={() => handleAdd()}
              >
                {intl.formatMessage({ id: "113167", defaultMessage: "Add" })}
              </button>
            </div>

            <hr />


          </div>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <BootstrapTable
            keyField="id"
            data={listData}
            columns={columns}
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            bootstrap4
          />
          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div>
            <Link to="/TransferOrder">
              <button type="button" className="btn btn-secondary wbt">
                {intl.formatMessage({
                  id: "107016",
                  defaultMessage: "Cancel",
                })}
              </button>
            </Link>
            <button className="btn btn-primary" onClick={() => handleSend()}>
              {intl.formatMessage({ id: "107017", defaultMessage: "Save" })}
            </button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
