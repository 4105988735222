import React, { useState, useEffect } from "react";
import { Stepper, Step, StepLabel } from "@material-ui/core";
import { useSubheader } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { useHistory, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { baseURL } from "../../../service/API";
import BootstrapTable from "react-bootstrap-table-next";
import axios from "axios";
import Swal from "sweetalert2";
import checkForbiddenSymbols from "../../../utils/regex";
import "../../../pages/component.css";
import { roleCheck } from "../../../service/role";

export const EditDeliveryNote = () => {
  const intl = useIntl();
  const suhbeader = useSubheader();
  const history = useHistory();
  const location = useLocation();
  const [deliveryNote, setDeliveryNote] = useState(null);
  const [confirmQty, setConfirmQty] = useState({});
  const { row } = location.state; // รับข้อมูลที่ถูกส่งมา
  const [regexError, setRegexError] = useState("");
  const [reason, setReason] = useState('')
  const [status, setStatus] = useState('')
  const [statusApp, setStatusApp] = useState('')
  const [isMainBranch, setIsMainBranch] = useState(false)
  const [campaigns_id_headder, setCampaigns_id_headder] = useState();
  const [roleList, setRoleList] = useState([]);
  suhbeader.setTitle(
    intl.formatMessage({ id: "113168", defaultMessage: "View Transfer Order" })
  );
  const checkAuth = async () => {
    const data = await roleCheck('TRANSFER_ORDER')
    setRoleList(data)
    console.log("data to setRoleList ->", data)
    if (!data.includes('VIEW')) {
      history.push('/auth/login')
    }
    let value = 1
    if (localStorage.getItem("pagePrinterZone") != null) {
      value = localStorage.getItem("pagePrinterZone")
    }
    let campaigns_id_headders = localStorage.getItem("campaigns_id_headder")
    setCampaigns_id_headder(campaigns_id_headders);
  }

  useEffect(() => {
    checkAuth();

  }, []);

  useEffect(() => {
    const fetchDeliveryNote = async () => {
      try {
        const response = await axios.get(`${baseURL}/deliverynote/detailBO/${row.id}`, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("Token")}`,
          },
        });
        setDeliveryNote(response.data.data);
        console.log("response.data.data", response.data);
        getStatus(response.data.data.status);
        setStatusApp(response.data.data.status_app);
        setIsMainBranch(response.data.is_main_branch)
      } catch (error) {
        console.error("Error fetching delivery note:", error);
      }
    };

    fetchDeliveryNote();
  }, [row.id]);

  const handleConfirmQtyChange = (itemId, value) => {
    setConfirmQty((prev) => ({
      ...prev,
      [itemId]: value,
    }));
  };

  const getSteps = () => {
    if (deliveryNote.status === 'CANCELED' && statusApp === 'DESTINATION_ACCEPTED') {
      return [
        intl.formatMessage({ id: "114010", defaultMessage: "เริ่มต้นการขนย้าย" }),
        intl.formatMessage({ id: "114011", defaultMessage: "แอพตอบตกลง" }),
        intl.formatMessage({ id: "114014", defaultMessage: "การขนย้ายไม่สำเร็จ" })
      ];
    } else if (deliveryNote.status === 'CANCELED' && !statusApp) {
      return [
        intl.formatMessage({ id: "114010", defaultMessage: "เริ่มต้นการขนย้าย" }),
        intl.formatMessage({ id: "114013", defaultMessage: "คำขอถูกยกเลิกกลางคัน" }),
        intl.formatMessage({ id: "114014", defaultMessage: "การขนย้ายไม่สำเร็จ" })
      ];
    }
    switch (deliveryNote.status) {
      case 'PENDING':
        return [
          intl.formatMessage({ id: "114010", defaultMessage: "เริ่มต้นการขนย้าย" }),
          "",
          ""
        ];
      case 'DESTINATION_ACCEPTED':
        return [
          intl.formatMessage({ id: "114010", defaultMessage: "เริ่มต้นการขนย้าย" }),
          intl.formatMessage({ id: "114011", defaultMessage: "แอพตอบตกลง" }),
          ""
        ];
      case 'DESTINATION_CANCELED':
        return [
          intl.formatMessage({ id: "114010", defaultMessage: "เริ่มต้นการขนย้าย" }),
          intl.formatMessage({ id: "114012", defaultMessage: "แอพตอบปฏิเสธ" }),
          ""
        ];
      case 'COMPLETED':
        return [
          intl.formatMessage({ id: "114010", defaultMessage: "เริ่มต้นการขนย้าย" }),
          intl.formatMessage({ id: "114011", defaultMessage: "แอพตอบตกลง" }),
          intl.formatMessage({ id: "114015", defaultMessage: "การขนย้ายเสร็จสิ้น" })
        ];
      case 'CANCELED':
        return [
          intl.formatMessage({ id: "114010", defaultMessage: "เริ่มต้นการขนย้าย" }),
          intl.formatMessage({ id: "114012", defaultMessage: "แอพตอบปฏิเสธ" }),
          intl.formatMessage({ id: "114014", defaultMessage: "การขนย้ายไม่สำเร็จ" })
        ];
      default:
        return [
          intl.formatMessage({ id: "114010", defaultMessage: "เริ่มต้นการขนย้าย" }),
          intl.formatMessage({ id: "114011", defaultMessage: "แอพตอบตกลง" }),
          intl.formatMessage({ id: "114015", defaultMessage: "การขนย้ายเสร็จสิ้น" })
        ];
    }
  };
  const getStatus = (status) => {
    switch (status) {
      case 'CANCELED':
        setStatus('การขนย้ายไม่สำเร็จ');
        break;
      case 'COMPLETED':
        setStatus('การขนย้ายเสร็จสิ้น');
        break;
      case 'PENDING':
        setStatus('เริ่มต้นการขนย้าย');
        break;
      case 'DESTINATION_ACCEPTED':
        setStatus('แอพตอบตกลง');
        break;
      case 'DESTINATION_CANCELED':
        setStatus('แอพตอบปฏิเสธ');
        break;
      case 'PENDING':
        setStatus('เริ่มต้นการขนย้าย');
        break;
      default:
        setStatus('สถานะไม่ทราบ');
        break;
    }
  };

  const getActiveStep = () => {
    switch (deliveryNote.status) {
      case 'PENDING':
        return 0;
      case 'DESTINATION_ACCEPTED':
        return 1;
      case 'DESTINATION_CANCELED':
        return 1;
      case 'COMPLETED':
        return 2;
      case 'CANCELED':
        return 2;
      default:
        return 0;
    }
  };

  // useEffect(() => {
  //   getStatus();
  // }, [deliveryNote])


  const columns = [
    {
      dataField: "no",
      text: "No.",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
    },
    {
      dataField: "inventory.bom_material.name",
      text: "Name",
      formatter: (cell, row) => row.inventory ? row.inventory?.bom_material?.name : 'No Inventory',
    },
    {
      dataField: "qty",
      text: "Quantity",
    },
    {
      dataField: "inventory.bom_material.major_unit_name",
      text: "Unit",
      formatter: (cell, row) => row.unit ? row.unit : 'No Inventory',
    },
    {
      dataField: "confirm_qty",
      text: "Confirm Qty",
      formatter: (cell, row) => row.confirm_qty !== null ? row.confirm_qty : '-',
    },
  ];

  if (!deliveryNote) {
    return <div>Loading...</div>;
  }

  const handleAccept = (action) => {
    let token = localStorage.getItem("Token");
    let swalTitle = 'Confirm Approve?' // beam intl plz
    let swalText = `you won't be able to re-edit it` //beam intl plz
    Swal.fire({
      title: swalTitle,
      text: swalText,
      icon: 'question',
      input: 'text',
      inputValue: reason,
      inputPlaceholder: 'Enter your reason (Optional)', // beam intl plz
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: {
        validationMessage: 'swal-validation-message'
      },
      inputValidator: (value) => {
        if (checkForbiddenSymbols(value)) {
          return 'เครื่องหมาย = < > ! ; \' " & |  ไม่ได้รับอนุญาตให้ใช้';
        }
        return null;
      }
    }).then((result) => {
      console.log("result.value ->", result.value)
      console.log("result ->", result)
      // console.log("value ->", value)
      if (result.isConfirmed && !regexError) {
        axios.put(`${baseURL}/deliveryNote/confirmBO/${deliveryNote.id}`, {
          data: {
            send_branch_id: deliveryNote.send_branch_id,
            received_branch_id: deliveryNote.received_branch_id,
            reason: result.value ? result.value : '-',
          }
        }, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }).then((res) => {
          Swal.fire("Success!", "The delivery request has been confirmed.", "success");
          history.push("/TransferOrder");
        }).catch((err) => {
          Swal.fire(
            "Failed!",
            "Your delivery request could not be confirmed.",
            "error"
          );
        });
      }
    });
  };
  const handleRecreate = () => {
    history.push({
      pathname: '/CreateDeliveryNote',
      state: { deliveryNote }
    });
  };
  const handleDecline = () => {
    let token = localStorage.getItem("Token");
    let swalText = 'tell us the reason you decline this request'; //beam intl plz
    Swal.fire({
      title: "Please submit your reason",
      text: swalText,
      input: 'text',
      inputValue: reason,
      inputPlaceholder: 'Enter your reason',
      inputAttributes: {
        style: 'background-color: white; height: 38px; width: 100%; border-radius: 5px; border-width: 0;'
      },
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: {
        validationMessage: 'swal-validation-message'
      },
      inputValidator: (value) => {
        if (!value) {
          return 'Reason is required';
        }
        if (checkForbiddenSymbols(value)) {
          return 'เครื่องหมาย = < > ! ; \' " & |  ไม่ได้รับอนุญาตให้ใช้';
        }
        return null;
      }
    }).then((result) => {
      if (result.value) {
        const reason = result.value;
        axios.put(`${baseURL}/deliveryNote/cancelBO/${deliveryNote.id}`, {
          data: {
            send_branch_id: deliveryNote.send_branch_id,
            received_branch_id: deliveryNote.received_branch_id,
            reason: reason,
          }
        }, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }).then((res) => {
          Swal.fire("Success!", "The delivery request has been confirmed.", "success");
          history.push("/TransferOrder");
        }).catch((err) => {
          Swal.fire(
            "Failed!",
            "Your delivery request could not be confirmed.",
            "error"
          );
        });
      }
    });
  };

  const handleReasonChange = (event) => {
    const value = event.target.value;
    if (!checkForbiddenSymbols(value)) {
      setRegexError("");
      setReason(value);
    } else {
      setRegexError(`เครื่องหมาย = < > ! ; ' " & |  ไม่ได้รับอนุญาตให้ใช้`);
    }
  };

  return (
    <div>
      <Card>
        <CardHeader
          title={intl.formatMessage({
            id: "113575",
            defaultMessage: "Edit Delivery Note",
          })}
        >
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <button
              type="button"
              // className="btn btn-secondary mr-2"
              className="btn btn-secondary" style={{ display: "flex", justifyContent: "flex-end", backgroundColor: "#3699FF", color: "white" }}
              onClick={() => history.push("/TransferOrder")}
            >
              {intl.formatMessage({ id: "113390", defaultMessage: "Back" })}
            </button>
          </div>
        </CardHeader>
        <CardBody>
          <div className="container">
            <Stepper activeStep={getActiveStep()} alternativeLabel className="custom-stepper">
              {getSteps().map((label) => (
                <Step key={label} className="step-label">
                  <StepLabel StepIconProps={{ classes: { text: 'stepper-text' } }}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div className="delivery-note-value" style={{ marginBottom: "25px", height: "45px" }}>
              <p style={{ marginTop: "15px", marginBottom: "0px", float: "left", width: "20%" }}>
                {intl.formatMessage({
                  id: "113567",
                  defaultMessage: "Origin Branch",
                })} :
              </p>
              {/* <div> */}
              <p style={{ marginTop: "15px", marginBottom: "0px" }}>{deliveryNote.send_branch_name}</p>
              {/* </div> */}
            </div>
            <div className="delivery-note-value" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                {intl.formatMessage({
                  id: "113568",
                  defaultMessage: "Destination Branch",
                })} :
              </p>
              <div>
                <p style={{ marginTop: "15px", marginBottom: "0px" }}>{deliveryNote.received_branch_name}</p>
              </div>
            </div>
            <div className="delivery-note-value" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                {intl.formatMessage({
                  id: "113169",
                  defaultMessage: "Description",
                })}
                :{" "}
              </p>
              <div>
                <p style={{ marginTop: "15px", marginBottom: "0px" }}>{deliveryNote.description}</p>
              </div>
            </div>
            <div className="delivery-note-value" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                {intl.formatMessage({
                  id: "113570",
                  defaultMessage: "Status",
                })} :
              </p>
              <div>
                <p style={{ marginTop: "15px", marginBottom: "0px" }}>{status}</p>
              </div>
            </div>
            <div className="delivery-note-value" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                {intl.formatMessage({
                  id: "113571",
                  defaultMessage: "Created At",
                })} :
              </p>
              <div>
                <p style={{ marginTop: "15px", marginBottom: "0px" }}>{new Date(deliveryNote.order_date).toLocaleString()}</p>
              </div>
            </div>
            <div className="delivery-note-value" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                {intl.formatMessage({
                  id: "113576",
                  defaultMessage: "Comment (App)",
                })} :
              </p>
              <div>
                <p style={{ marginTop: "15px", marginBottom: "0px" }}>{deliveryNote.reason_app ? deliveryNote.reason_app : "-"}</p>
              </div>
            </div>
            <div className="delivery-note-value" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                {intl.formatMessage({
                  id: "113577",
                  defaultMessage: "Comment (Back Office)",
                })} :
              </p>
              <div>
                {/* {deliveryNote.status === 'DESTINATION_ACCEPTED' || deliveryNote.status === 'DESTINATION_CANCELED' ? (
                  <input
                    type="text"
                    value={reason}
                    onChange={handleReasonChange}
                    style={{
                      height: "38px",
                      width: "530px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                    }}
                  />
                ) : ( */}
                <p style={{ marginTop: "15px", marginBottom: "0px" }}>{deliveryNote.reason_bo ? deliveryNote.reason_bo : "-"}</p>
                {/* )} */}
                {regexError && <p style={{ color: "red", marginTop: "5px" }}>{regexError}</p>}
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="container">
            <BootstrapTable
              keyField="item_id"
              data={deliveryNote.deliveryNoteDetail}
              columns={columns}
              wrapperClasses="table-responsive"
              bordered={false}
              classes="table table-head-custom table-vertical-center overflow-hidden"
              bootstrap4
            />
          </div>
          {isMainBranch != 0 && roleList.includes('CREATE') && roleList.includes('EDIT') && (
          <div className="delivery-note-value" style={{ marginTop: "25px", display: "flex", justifyContent: "flex-end" }}>
            {deliveryNote.status === 'COMPLETED' || deliveryNote.status === 'CANCELED' ? (
              <button
                type="button"
                className="btn btn-warning"
                onClick={handleRecreate}
                style={{ backgroundColor: "#3699FF", color: "white", border: "none" }}
              >
                {intl.formatMessage({ id: "114005", defaultMessage: "Re-create" })}
              </button>
            ) : (
              <>
                  {(deliveryNote.status !== 'COMPLETED' && deliveryNote.status !== 'CANCELED' && deliveryNote.status !== 'DESTINATION_CANCELED' && deliveryNote.status !== 'PENDING' && isMainBranch != 0) && (
                    <button
                      type="button"
                      className="btn btn-primary mr-2"
                      onClick={handleAccept}
                    >
                      {intl.formatMessage({ id: "113573", defaultMessage: "Accept" })}
                    </button>
                  )}
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleDecline}
                  disabled={deliveryNote.status === 'COMPLETED' || deliveryNote.status === 'CANCELED' || isMainBranch == 0}
                >
                  {intl.formatMessage({ id: "113574", defaultMessage: "Decline" })}
                </button>
              </>
            )}
          </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};