import React, { useState, useEffect } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../_metronic/_partials/controls/index.js";
import BootstrapTable from "react-bootstrap-table-next";
import {
    NoRecordsFoundMessage,
    PleaseWaitMessage,
    sortCaret,
    headerSortingClasses,
} from "../../_metronic/_helpers/index.js";
import { useSubheader } from "../../_metronic/layout/index.js";
import { Link, useHistory } from "react-router-dom";
import { baseURL } from "../service/API.js";
import moment from "moment";
import Swal from "sweetalert2";
import axios from "axios";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-pagination-library/build/css/index.css";
import Pagination2 from "@material-ui/lab/Pagination";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { roleCheck } from '../service/role.js';
import checkForbiddenSymbols from '../utils/regex.js';
export const TransferRequest = () => {
    const intl = useIntl();
    const suhbeader = useSubheader();
    const allData = [];
    const [isMainBranch, setIsMainBranch] = useState(false);
    const [settingPrinterZone, setSettingPrinterZoneData] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalSize, setTotalSize] = useState(100);
    suhbeader.setTitle(intl.formatMessage({ id: "113585", defaultMessage: "Transfer Request" }));
    const { authToken } = useSelector((state) => state.auth);
    const [campaigns_id_headder, setCampaigns_id_headder] = useState();
    const [roleList, setRoleList] = useState([]);

    const history = useHistory();
    useEffect(() => {
        localStorage.removeItem('edit')
        checkAuth();

    }, []);

    const checkAuth = async () => {
        const data = await roleCheck('TRANSFER_REQUEST')
        setRoleList(data)
        if (!data.includes('VIEW')) {
            history.push('/auth/login')
        }
        let value = 1
        if (localStorage.getItem("pagePrinterZone") != null) {
            value = localStorage.getItem("pagePrinterZone")
        }
        let campaigns_id_headders = localStorage.getItem("campaigns_id_headder")
        setCampaigns_id_headder(campaigns_id_headders);
        getList(value, 10, search, campaigns_id_headders);

    }

    const getList = async (page, limit, search, campaigns_id_headders) => {
        setPage(page);
        axios({
            method: "get",
            url: `${baseURL}/deliveryNote?page=${page}&limit=${limit}&type=TRANSFER_REQUESTED${search !== undefined && search !== "" ? "&search=" + search : ""
                }`,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${authToken}`,
            },
        }).then((res) => {
            if (res.message) {
                history.push("/logout");
            } else {
                console.log("res.data.is_main_branch ->", res.data.mainBranch)
                res.data.data.map((data, index) => {
                    data = {
                        id: data.id,
                        zone_name: data.zone_name,
                        zone_name_en: data.zone_name_en,
                        shop_id: data.shop_id,
                        branch_id: data.branch_id,
                        enabled: data.enabled ? true : false,
                        description: data.description,
                        status: data.status,
                        order_no: data.order_no,
                        created_at: data.created_at,
                        name: data.posBranch ? data.posBranch.name : 'NONE',
                        send_branch_id: data.send_branch_id,
                        received_branch_id: data.received_branch_id,
                    };
                    allData.push(data);
                });
                setIsMainBranch(res.data.mainBranch)
            }

            var totalpage = Math.ceil(res.data.total / res.data.limit);
            setTotalPage(totalpage);
            setTotalSize(res.data.total);
            setLimit(res.data.limit);
            setSettingPrinterZoneData(allData);
        });
    };



        useEffect(() => {
              console.log("isMainBranch ->", isMainBranch)

        }, [isMainBranch])
        


    const columns = [
        {
            dataField: "id",
            text: "ID",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            ortFunc: (a, b, order) => {
                if (order === 'asc') {
                    return a - b;
                }
                return b - a;
            },
        },
        {
            dataField: "order_no",
            text: "Order No",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            ortFunc: (a, b, order) => {
                if (order === 'asc') {
                    return a - b;
                }
                return b - a;
            },
        },
        {
            dataField: "status",
            text: "Status",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: (cell) => {
                switch (cell) {
                    case "PENDING":
                        return intl.formatMessage({ id: "113587", defaultMessage: "Pending" });
                    case "COMPLETED":
                        return intl.formatMessage({ id: "113588", defaultMessage: "Transfer Request Completed" });
                    case "CANCELED":
                        return intl.formatMessage({ id: "113589", defaultMessage: "Transfer Request Canceled" });
                    default:
                        return cell;
                }
            },
        },
        {
            dataField: "description",
            text: "description",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "send_branch_id",
            text: intl.formatMessage({ id: "113590", defaultMessage: "Origin Branch id" }),
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "received_branch_id",
            text: intl.formatMessage({ id: "113591", defaultMessage: "Destination Branch id" }),
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "created_at",
            text: 'created at',
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            text: intl.formatMessage({ id: "107008", defaultMessage: "Action" }),
            dataField: "id",
            formatter: (id, row, rowIndex) => {
                return (
                    <div className="form-inline">
                        {roleList.includes('VIEW') && (
                            <div className="mr-3">
                                <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    onClick={() => editItem({ row, rowIndex })}
                                >
                                    {intl.formatMessage({ id: "113409", defaultMessage: "View" })}
                                </button>
                            </div>
                        )}
                        {roleList.includes('DELETE') && row.status !== 'COMPLETED' && row.status !== 'CANCELED' && isMainBranch == true && (
                            <div>
                                <button
                                    type="button"
                                    className="btn btn-outline-danger"
                                    onClick={() => handleVerify("cancel", row)}
                                >
                                    {intl.formatMessage({ id: "113574", defaultMessage: "Decline" })}
                                </button>
                            </div>
                        )}
                    </div>
                );
            },
        },
    ];

    const editItem = (data) => {
        history.push("/ViewTransferRequest", data);
    };

    const handleVerify = async (method, deliveryNote) => {
        const token = localStorage.getItem("Token");
        const isCancel = method === "cancel";
        const swalTitle = isCancel ? "Confirm Decline?" : "Confirm Approve?";
        const swalText = isCancel
            ? "Please provide a reason for declining this request."
            : "Transfer Order will be created with this information";

        const result = await Swal.fire({
            title: swalTitle,
            text: swalText,
            icon: "question",
            input: "text",
            inputValue: "",
            inputPlaceholder: isCancel
                ? "Enter your reason (Required)"
                : "Enter your reason (Optional)",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            customClass: {
                validationMessage: "swal-validation-message",
            },
            inputValidator: (value) => {
                if (isCancel && !value) {
                    return "Reason is required for declining.";
                }
                if (checkForbiddenSymbols(value)) {
                    return 'เครื่องหมาย = < > ! ; \' " & |  ไม่ได้รับอนุญาตให้ใช้';
                }
                return null;
            },
        });

        if (result.isConfirmed) {
            const note = result.value || "-";

            try {
                const url = `${baseURL}/deliveryNote/verifyBO/${deliveryNote.id}?method=cancel`;

                const body =
                    {
                        // send_branch_id: deliveryNote.send_branch_id,
                        // received_branch_id: deliveryNote.received_branch_id,
                        reason: note,
                    }

                await axios.post(url, body, {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                });

                Swal.fire(
                    "Success!",
                    isCancel
                        ? "The delivery request has been declined."
                        : "The delivery request has been confirmed.",
                    "success"
                ).then(() => {
                    window.location.reload();
                });
            } catch (err) {
                console.error("Error:", err);
                Swal.fire(
                    "Failed!",
                    isCancel
                        ? "Your delivery request could not be declined."
                        : "Your delivery request could not be confirmed.",
                    "error"
                );
            }
        }
    };

    const [search, setSearch] = useState();

    useEffect(() => {
        if (search === "") {
            handleSearch();
        }
    }, [search]);

    const handleSearch = () => {
        setPage(1);
        if (!search || search.trim() === "") {
            getList(1, limit, undefined, campaigns_id_headder);
        } else {
            getList(1, limit, search, campaigns_id_headder);
        }
    };

    const handlePageChange = (event, value) => {
        setPage(value);
        localStorage.setItem("pagePrinterZone", value)
        getList(value, limit, search, campaigns_id_headder);
    };

    const handlePageSizeChange = (event) => {
        setLimit(event.target.value);
        getList(1, event.target.value, search, campaigns_id_headder);
    };

    return (
        <Card>
            <CardHeader title={intl.formatMessage({ id: "113585", defaultMessage: "Transfer Request" })}>
            </CardHeader>
            <CardBody>
                <div className="row form-inline mb-2">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder={intl.formatMessage({ id: "113583", defaultMessage: "Search by ID" })}
                                style={{ width: "100%" }}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-2">
                        <div className="form-group">
                            <button
                                type="button"
                                id="button"
                                className="btn btn-primary"
                                style={{ width: "100%" }}
                                onClick={() => handleSearch()}
                            >
                                {intl.formatMessage({ id: "107003", defaultMessage: "search" })}
                            </button>
                        </div>
                    </div>
                </div>

                <BootstrapTable
                    keyField="id"
                    data={settingPrinterZone === undefined ? [] : settingPrinterZone}
                    columns={columns}
                    wrapperClasses="table-responsive"
                    bordered={false}
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    bootstrap4
                >
                    <PleaseWaitMessage entities={settingPrinterZone} />
                    <NoRecordsFoundMessage entities={settingPrinterZone} />
                </BootstrapTable>
                <div className=" col-lg-12">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="mt-3">
                                {intl.formatMessage({ id: "107032", defaultMessage: "Item per page" })}
                                <select onChange={handlePageSizeChange} value={limit}>
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <Pagination2
                                className="mt-3 "
                                count={totalPage}
                                page={localStorage.getItem("pagePrinterZone") != null ? Number(localStorage.getItem("pagePrinterZone")) : page}
                                siblingCount={1}
                                boundaryCount={1}
                                variant="outlined"
                                shape="rounded"
                                onChange={handlePageChange}
                            />
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};
