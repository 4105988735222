//regex ดัก dangerous html ตรงนนี้ แล้ว export ไปใช้ที่อื่น

export default function checkForbiddenSymbols(input) {
    // Regular expression ที่ใช้ตรวจจับสัญลักษณ์ที่ต้องห้าม
    const forbiddenSymbolsRegex = /[=<>!;'"&|]/;
  
    // เช็คว่า input มีสัญลักษณ์ที่ต้องห้ามหรือไม่
    if (forbiddenSymbolsRegex.test(input)) {
      return true;  // ถ้ามีสัญลักษณ์ที่ไม่อนุญาต
    } else {
      return false; // ถ้าไม่มีสัญลักษณ์ที่ไม่อนุญาต
    }
  }
