import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout/index.js";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls/index.js";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { baseURL, imageURL } from "../../../service/API.js";
import Switch from "@material-ui/core/Switch";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import CropperImage from "../CropperImage.jsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
// import { isNull } from "lodash";
import { Modal, Button, Alert } from "react-bootstrap"
// import { CreateMenuGroup } from '../create/CreateMenuGroup.js'
import { useIntl } from "react-intl";
import { temp_store } from "../../../modules/function/temp_store.js"

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import { validateLanguage } from "../../../service/validationLanguage.js";
import { CreateMenuGroupV3 } from "../create/CreateMenuGroupV3.js";
import { set } from "lodash";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  noLabel: {
    marginTop: theme.spacing.unit * 3,
  },
  inputColor: {
    backgroundColor: "#F4F6F9",
    borderRadius: "5px",
    borderWidth: "0",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export const EditMenuBuffetV2 = (props) => {
  const intl = useIntl();
  const suhbeader = useSubheader();
  suhbeader.setTitle(intl.formatMessage({ id: "112001", defaultMessage: "Buffet Menu" }));
  const history = useHistory();
  const classes = useStyles();
  const [group_id, setGroup_id] = useState();
  const [template_id, setTemplate_id] = useState();
  const [template, setTemplate] = useState([]);
  const [optionTemplate, setOptionTemplate] = useState([]);
  const [cloneTemplate, setCloneTemplate] = useState(false);
  const [item_name, setItem_name] = useState("");
  const [item_name_en, setItem_name_en] = useState("");
  const [item_name_cn, setItem_name_cn] = useState("");
  const [item_name_jp, setItem_name_jp] = useState("");
  const [item_name_kr, setItem_name_kr] = useState("");
  const [item_name_id, setItem_name_id] = useState("");
  const [description, setDescription] = useState("");
  const [description_en, setDescription_en] = useState("");
  const [description_cn, setDescription_cn] = useState("");
  const [description_jp, setDescription_jp] = useState("");
  const [description_kr, setDescription_kr] = useState("");
  const [description_id, setDescription_id] = useState("");
  const [description_receipt, setDescription_receipt] = useState("");
  const [item_image_path, setItem_image_path] = useState();
  const [nameImageItem_image_path, setNameImageItem_image_path] = useState("");
  const [uom, setUom] = useState();
  const [price, setPrice] = useState();
  const [state, setState] = useState(true);
  const [buffet_package, setBuffet_package] = useState(true);
  const [changeOption, setChangeOption] = useState(false);
  const [group, setGroup] = useState([]);
  const [displayOrder, setDisplayOrder] = useState(0)
  const [show, setShow] = useState(false);
  const [printer_zone_data, setprinter_zone_data] = useState([]);
  const [zoneprinter_data, setzoneprinter_data] = useState([]);
  const [enable_cn, setEnable_cn] = useState(false);
  const [changeName, setChangeName] = useState(false);
  const [template_name, setTemplateName] = useState("");
  const [loading, setLoading] = useState({
    upload: false,
    submit: false,
  });
  // const [loading, setLoading] = useState(false);

  const [stateItem_image_path, setStateItem_image_path] = useState({
    previewItem_image_path: null,
    rawItem_image_path: null,
    imageURLItem_image_path: null,
  });

  const [enableTimeCounting, setEnableTimeCounting] = useState(false);
  const [timePeriod, setTimePeriod] = useState("");

  const handleChangeNameOption = () => {
    setChangeName(!changeName);
  };
  const allDataGroup = [];

  const AlertError = (message) => {
    Swal.fire(
      intl.formatMessage({ id: "113050", defaultMessage: "Menu Management!" }),
      message,
      "error"
    );
  };

  useEffect(() => {
    getGroup();
    getZonePrint();
    getTemplateGroup();
    setGroup_id(temp_store(props).location.state.row.group_id);
    // console.log('props', props)
    if (temp_store(props).location.state.row.template_id) {
      setTemplate_id(temp_store(props).location.state.row.template_id);
      setOptionTemplate(temp_store(props).location.state.row.options_template);
      let show_option =
        temp_store(props).location.state.row.template_id !== null;
      setChangeOption(show_option);
    }
    setItem_name(temp_store(props).location.state.row.item_name);
    setItem_name_en(temp_store(props).location.state.row.item_name_en);
    setItem_name_cn(temp_store(props).location.state.row.item_name_cn);
    setItem_name_jp(temp_store(props).location.state.row.item_name_jp);
    setItem_name_kr(temp_store(props).location.state.row.item_name_kr);
    setItem_name_id(temp_store(props).location.state.row.item_name_id);
    setDescription(temp_store(props).location.state.row.description);
    setDescription_en(temp_store(props).location.state.row.description_en);
    setDescription_cn(temp_store(props).location.state.row.description_cn);
    setDescription_jp(temp_store(props).location.state.row.description_jp);
    setDescription_kr(temp_store(props).location.state.row.description_kr);
    setDescription_id(temp_store(props).location.state.row.description_id);
    setDescription_receipt(temp_store(props).location.state.row.description_receipt);
    setDisplayOrder(temp_store(props).location.state.row.displayOrder);
    setItem_image_path(temp_store(props).location.state.row.item_image_path);
    setTemplateName(temp_store(props).location.state.row.template_name);

    if (temp_store(props).location.state.row.uom) {
      setUom(temp_store(props).location.state.row.uom);
    }

    setPrice(temp_store(props).location.state.row.price);
    setState(temp_store(props).location.state.row.enabled);

    let time_period = temp_store(props).location.state.row.buffet_time;
    setTimePeriod((time_period == null || time_period == '' ? null : time_period));
    // setTimePeriod(temp_store(props).location.state.row.buffet_time);
    setBuffet_package(temp_store(props).location.state.row.is_choose)

    checkEnableCN();
  }, []);

  useEffect(() => {
    console.log("optionTemplate ===>", optionTemplate);
  }, [optionTemplate]);
  const checkEnableCN = () => {
    let temp = localStorage.getItem('is_enable_cn')
    if (temp == '1') {
      setEnable_cn(true)
    }
  }
  const getZoneprinter_data = (id) => {
    try {
      let print = zoneprinter_data.find(data => Number(data.id) === Number(id))
      return zoneprinter_data.find(data => Number(data.id) === Number(id))
    } catch (error) {
      return ""
    }
  }

  const getZonePrint = async () => {
    let apiUrls = `${baseURL}/management/printerzone?limit=10000&page=1`;
    let token = localStorage.getItem("Token");
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        "Accept-Language": "th",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        setzoneprinter_data(res.data.data);
        let print = res.data.data.find(data => Number(data.id) === Number(temp_store(props).location.state.row.printer_zone_id))
        setprinter_zone_data(print)

      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getGroup = async () => {
    let apiUrls = `${baseURL}/management/group?limit=10000&page=1`;
    let token = localStorage.getItem("Token");
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        "Accept-Language": "th",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        setGroup(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleChangeGroup = (event) => {
    setGroup_id(event.target.value);
  };
  const handleChangeTemplate = (event) => {
    setTemplate_id(event.target.value);
    let template_select = template.find(arr => arr.id == event.target.value)
    // console.log('template_select', template_select);
    if (template_select) {
      setOptionTemplate(template_select.options_template)
    } else {
      setOptionTemplate([])
    }
    setCloneTemplate(false)
  };
  const getTemplateGroup = async () => {
    let apiUrls = `${baseURL}/management/templatelist`;
    let token = localStorage.getItem("Token");
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        "Accept-Language": "th",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        setTemplate(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangeItem_image_path = async (e) => {
    if (e.target.files.length) {
      e.preventDefault();
      let file = e.target.files[0];
      var reader = new FileReader();
      e.target.value = '';
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        var image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          var height = this.height;
          var width = this.width;
          if (height < 512 || width < 512) {
            Swal.fire(
              "Upload !",
              "At least you can upload a 512*512 photo size. !! ",
              "error"
            );
            e.target.value = '';
            return false;
          } else {
            setStateItem_image_path({
              rawItem_image_path: file,
              imageURLItem_image_path: e.target.result,
            });
            e.target.value = '';
            return true;
          }
        };
      };
    }
  };
  const handleImageUploadItem_image_path = async (file) => {
    setLoading({
      ...loading,
      upload: true
    });
    // setLoading(true);
    var formdata = new FormData();
    formdata.append("image", file, URL.createObjectURL(file));
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(`${baseURL}/upload/`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let res = JSON.parse(result);
        // console.log(res);
        setStateItem_image_path({
          previewItem_image_path: null,
          rawItem_image_path: null,
          imageURLItem_image_path: null,
        });
        setItem_image_path(`${imageURL}/${res.filename}`);
        setNameImageItem_image_path(res.filename);
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        setLoading({
          ...loading,
          upload: false
        });
        // setLoading(false);
      });
  };
  const handleImageUploadItem_image_pathOriginal = async (file) => {
    var formdata = new FormData();
    formdata.append("image", file, URL.createObjectURL(file));
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(`${baseURL}/upload/`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let res = JSON.parse(result);
        console.log(res);
        setStateItem_image_path({
          previewItem_image_path: null,
          rawItem_image_path: null,
          imageURLItem_image_path: null,
        });
        setItem_image_path(`${imageURL}/${res.filename}`);
        setNameImageItem_image_path(res.filename);
      })
      .catch((error) => console.log("error", error));
  };

  const removeImageItem_image_path = async () => {
    setStateItem_image_path({
      previewItem_image_path: null,
      rawItem_image_path: null,
      imageURLItem_image_path: null,
    });
    setItem_image_path("");
  };

  const handleCancelUploadItem_image_path = () => {
    setStateItem_image_path({
      previewItem_image_path: null,
      rawItem_image_path: null,
      imageURLItem_image_path: null,
    });
  };

  const handleChangeSwitch = () => {
    setState(!state);
  };

  const handleChangeSwitchPackage = () => {
    setBuffet_package(!buffet_package);
  };

  const handleChangeOption = () => {
    setChangeOption(!changeOption);
    if (changeOption) {
      setTemplate_id("");
      setOptionTemplate([]);
    }
  };

  const handleChangezoneprinter = (event, values) => {
    if (values) {
      setprinter_zone_data(values);
      console.log(values)
    } else {
      setprinter_zone_data();
    }
  };

  const onSubmit = async (data) => {
    if (item_name === undefined || item_name === "") {
      return AlertError("please insert item name.");
    }
    // if (item_name_en === undefined || item_name_en === "") {
    //   return AlertError("please insert item name en.");
    // }

    // ของเก่า เป็นแบบผสม ไทยอังกฤษ เลยต้องเอา validate ออก
    // if (!validateLanguage(item_name, "Thai", intl, AlertError)) return;
    // if (description && !validateLanguage(description, "Thai", intl, AlertError)) return;

    // if (item_name_en && !validateLanguage(item_name_en, "English", intl, AlertError)) return;
    // if (description_en && !validateLanguage(description_en, "English", intl, AlertError)) return;

    // if (item_name_cn && !validateLanguage(item_name_cn, "Chinese", intl, AlertError)) return;
    // if (description_cn && !validateLanguage(description_cn, "Chinese", intl, AlertError)) return;

    // if (item_name_jp && !validateLanguage(item_name_jp, "Japanese", intl, AlertError)) return;
    // if (description_jp && !validateLanguage(description_jp, "Japanese", intl, AlertError)) return;

    // if (item_name_kr && !validateLanguage(item_name_kr, "Korean", intl, AlertError)) return;
    // if (description_kr && !validateLanguage(description_kr, "Korean", intl, AlertError)) return;

    // if (item_name_id && !validateLanguage(item_name_id, "Indian", intl, AlertError)) return;
    // if (description_id && !validateLanguage(description_id, "Indian", intl, AlertError)) return;

    if (group_id === undefined || group_id === "") {
      return AlertError("please select group.");
    }

    if (price === undefined || price === "") {
      if (changeOption) {
        setPrice(0)
      } else return AlertError("please insert price.");
    }

    if (description_receipt === undefined || description_receipt === "") {
      return AlertError("please insert description receipt.");
    }

    if (displayOrder === undefined || displayOrder === '' || isNaN(displayOrder) || Number(displayOrder) === 0 || Number(displayOrder) < 0) {
      return AlertError('please insert display order.')
    }

    if (printer_zone_data === undefined || printer_zone_data === "" || printer_zone_data === null) {
      return AlertError("please select printer zone")
    }

    // console.log("first optionTemplate ===>", optionTemplate)
    if (optionTemplate.length > 0) {
      // เช็คว่ามี option group ที่ไม่มีชื่อหรือไม่
      const checkOptionGroupName = optionTemplate.some((item) => item.option_group_name.trim() === "");
      if (checkOptionGroupName) {
        return AlertError(intl.formatMessage({ id: "114016", defaultMessage: "Please insert option group name." }));
      }
      // เช็คว่ามี option ที่ไม่มีชื่อหรือไม่
      const checkOptionName = optionTemplate.some((item) =>
        item.options.some((option) => option.option_name.trim() === "")
      );
      if (checkOptionName) {
        return AlertError(intl.formatMessage({ id: "114017", defaultMessage: "Please insert option name." }));
      }

      // เช็คว่ามี optionGroup option_pick_min > option_pick_max หรือไม่
      const checkOptionPickMinMax = optionTemplate.some((item) =>
        item.option_pick_min > item.option_pick_max);
      if (checkOptionPickMinMax) {
        return AlertError(intl.formatMessage({ id: "114024", defaultMessage: "Option pick min must less than option pick max." }));
      }

      // เช็คว่าในแต่ละ optionGroup มี option หรือไม่ ถ้าไม่มีให้แจ้งเตือน ว่าต้องมี option อย่างน้อย 1 ตัว
      const checkOption = optionTemplate.some((item) =>
        item.options.length === 0);
      if (checkOption) {
        return AlertError(intl.formatMessage({ id: "114025", defaultMessage: "Please insert option." }));
      }
    }

    let get_shop_id = localStorage.getItem("shop_id");
    let get_branch_id = localStorage.getItem("branch_id");

    console.log("eventOptionTemplate ===>", optionTemplate);

    var events = {
      type: "MENU",
      shop_id: get_shop_id,
      branch_id: get_branch_id,
      group_id,
      template_id: template_id ? template_id : null,
      item_name,
      item_name_en: item_name_en ? item_name_en : null,
      item_name_cn: item_name_cn ? item_name_cn : null,
      item_name_jp: item_name_jp ? item_name_jp : null,
      item_name_kr: item_name_kr ? item_name_kr : null,
      item_name_id: item_name_id ? item_name_id : null,
      description: description ? description : null,
      description_en: description_en ? description_en : null,
      description_cn: description_cn ? description_cn : null,
      description_jp: description_jp ? description_jp : null,
      description_kr: description_kr ? description_kr : null,
      description_id: description_id ? description_id : null,
      description_receipt: description_receipt ? description_receipt : null,
      item_image_path: item_image_path ? item_image_path : null,
      uom: uom,
      price: Number(price),
      printer_zone_id: printer_zone_data ? printer_zone_data.id : null,
      enabled: state,
      display_order: displayOrder === 0 ? 0 : displayOrder,
      option_template: optionTemplate,
      buffet_time: (timePeriod == null || timePeriod == '' ? null : timePeriod),
      is_choose: buffet_package
    };

    let apiUrls = `${baseURL}/management/menu/${temp_store(props).location.state.row.id}`;
    let token = localStorage.getItem("Token");

    setLoading({
      ...loading,
      submit: true,
    });
    // setLoading(true);

    // const resultAll = axios({
    axios({
      method: "put",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: events,
    })
      .then(async (res) => {
        history.goBack();
      })
      .catch((err) => {
        Swal.fire(
          "Edit !",
          "Your can not update menu. !! " + err,
          "error"
        );
      })
      .finally(() => {
        setLoading({
          ...loading,
          submit: false,
        });
        // setLoading(false);
      });
  };
  // console.log('optionTemplate ', optionTemplate, 'template_id', template_id)
  const BindOption = (type) => {
    return (
      <>
        {optionTemplate.map((group, index) => {
          let indexGroup = index;
          if (group.type == type) {
            return (
              <div className="option-box" key={index}>
                <div>
                  <div className="d-flex align-items-center">
                    <Switch
                      checked={group.is_active}
                      onChange={(e) => setOptionGroup(index, e, "is_active")}
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      className="input-cheked-show"
                    />
                    <span className="ml-3">
                      {intl.formatMessage({
                        id: "###",
                        defaultMessage: "เปิด/ปิด option Group นี้",
                      })}
                    </span>
                  </div>
                  <div className={`group-name d-flex align-items-center${group.validateGroup ? ' validate' : ''}`}>
                    <span className="mr-5 name w-25">
                      <span style={{ color: "red" }}>
                        *
                      </span>
                      {intl.formatMessage({ id: "113121", defaultMessage: "group name TH" })}
                    </span>
                    <input
                      className="flex-grow-1"
                      type="text" minLength="100"
                      value={group.option_group_name}
                      onChange={(event) => setOptionGroup(index, event, "option_group_name")}
                    // disabled={!editTemplate} 
                    />
                  </div>
                  <div className={`group-name d-flex align-items-center`}>
                    <span className="mr-5 name w-25">
                      {intl.formatMessage({ id: "113122", defaultMessage: "group name EN" })}
                    </span>
                    <input
                      className="flex-grow-1"
                      type="text"
                      minLength="100"
                      value={group.option_group_name_en}
                      onChange={(event) => setOptionGroup(index, event, "option_group_name_en")}
                    // disabled={!editTemplate} 
                    />
                  </div>
                  {/* <div
                    className={`group-name d-flex align-items-center${
                      group.validate_en ? " validate" : ""
                    }`}
                  >
                    {enable_cn && <div>
                      <span className="mr-5 name">{intl.formatMessage({ id: "103124", defaultMessage: "group name CN" })}</span>
                      <input type="text" minLength="100" value={group.option_group_name_cn} onChange={(event) => setOptionGroup(index, event, "option_group_name_cn")} />
                    </div>}

                    <span
                      className="option-delete d-flex text-primary ml-3 btn-custom"
                      onClick={() => deleteGroup(index)}
                    >
                      <i className="fa fa-trash mr-3 text-primary"></i>
                      {intl.formatMessage({
                        id: "104038",
                        defaultMessage: "delete",
                      })}
                    </span>
                  </div> */}
                  <div className={`group-name d-flex align-items-center`}>
                    <span className="mr-5 name w-25">
                      {intl.formatMessage({ id: "103124", defaultMessage: "group name CN" })}
                    </span>
                    <input
                      className="flex-grow-1"
                      type="text" minLength="100"
                      value={group.option_group_name_cn}
                      onChange={(event) => setOptionGroup(index, event, "option_group_name_cn")}
                    // disabled={!editTemplate}
                    />
                  </div>
                  <div className={`group-name d-flex align-items-center`}>
                    <span className="mr-5 name w-25">
                      {intl.formatMessage({ id: "113560", defaultMessage: "group name JP" })}
                    </span>
                    <input
                      className="flex-grow-1"
                      type="text" minLength="100"
                      value={group.option_group_name_jp}
                      onChange={(event) => setOptionGroup(index, event, "option_group_name_jp")}
                    // disabled={!editTemplate}
                    />
                  </div>
                  <div className={`group-name d-flex align-items-center`}>
                    <span className="mr-5 name w-25">
                      {intl.formatMessage({ id: "113561", defaultMessage: "group name KR" })}
                    </span>
                    <input
                      className="flex-grow-1"
                      type="text" minLength="100"
                      value={group.option_group_name_kr}
                      onChange={(event) => setOptionGroup(index, event, "option_group_name_kr")}
                    // disabled={!editTemplate}
                    />
                  </div>
                  <div className={`group-name d-flex align-items-center`}>
                    <span className="mr-5 name w-25">
                      {intl.formatMessage({ id: "113562", defaultMessage: "group name IN" })}
                    </span>
                    <input
                      className="flex-grow-1"
                      type="text" minLength="100"
                      value={group.option_group_name_id}
                      onChange={(event) => setOptionGroup(index, event, "option_group_name_id")}
                    // disabled={!editTemplate}
                    />
                  </div>
                  <div className="d-flex justify-content-end">
                    <span
                      className="option-delete d-flex text-primary ml-3 btn-custom"
                      onClick={() => deleteGroup(index)}>
                      <i className="fa fa-trash mr-3 text-primary"></i>
                      {intl.formatMessage({ id: "104038", defaultMessage: "delete" })}
                    </span>
                  </div>
                </div>
                <div className="option-list overflow-auto">
                  <div className="d-flex align-items-center mb-1 font-weight-bold">
                    <span className="option-name col-2 mr-1">
                      {intl.formatMessage({ id: "113566", defaultMessage: "status", })}
                    </span>
                    <span className="option-name col-4 mr-1">
                      <span style={{ color: "red" }}>
                        *
                      </span>
                      {intl.formatMessage({ id: "104039", defaultMessage: "Option name th" })}
                    </span>
                    <span className="option-name col-4 mr-1">
                      {intl.formatMessage({ id: "103123", defaultMessage: "Option name en" })}
                    </span>
                    {/* {enable_cn && <span className="option-name col-3 mr-1">{intl.formatMessage({ id: "103125", defaultMessage: "Option name cn" })}</span>} */}
                    <span className="option-name col-4 mr-1">
                      {intl.formatMessage({ id: "103125", defaultMessage: "Option name cn" })}
                    </span>
                    <span className="option-name col-4 mr-1">
                      {intl.formatMessage({ id: "113563", defaultMessage: "Option name jp" })}
                    </span>
                    <span className="option-name col-4 mr-1">
                      {intl.formatMessage({ id: "113564", defaultMessage: "Option name kr" })}
                    </span>
                    <span className="option-name col-4 mr-1">
                      {intl.formatMessage({ id: "113565", defaultMessage: "Option name in" })}
                    </span>
                    <span className="option-price col-2 mr-3">
                      {intl.formatMessage({ id: "104040", defaultMessage: "price" })}</span>
                    <span className="option-default mr-6">
                      {intl.formatMessage({ id: "104041", defaultMessage: "show price" })}
                    </span>
                    <span className="option-default" style={{ minWidth: '51px' }}>
                      {intl.formatMessage({ id: "113500", defaultMessage: "Set Default", })}
                    </span>
                  </div>
                  {group.options.map((option, index) => {
                    return (
                      <div className="d-flex align-items-center mb-1" key={index} >
                        <div className="option-name col-2 mr-1" maxLength="40">
                          <Switch
                            checked={option.is_active}
                            onChange={(e) => setOption(indexGroup, index, e, "is_active")}
                            inputProps={{ "aria-label": "secondary checkbox" }}
                            className="input-cheked-show"
                          />
                        </div>
                        <input
                          className={`option-name col-4 mr-1 ${option.validateOption ? 'border border-danger' : ''}`}
                          maxLength="40"
                          type="text"
                          value={option.option_name}
                          onChange={(event) => setOption(indexGroup, index, event, 'option_name')}
                        />
                        <input
                          className="option-name col-4 mr-1"
                          maxLength="40"
                          type="text"
                          value={option.option_name_en}
                          onChange={(event) => setOption(indexGroup, index, event, 'option_name_en')}
                        />
                        <input
                          className="option-name col-4 mr-1"
                          maxLength="120" type="text" value={option.option_name_cn}
                          onChange={(event) => setOption(indexGroup, index, event, 'option_name_cn')}
                        />
                        <input
                          className="option-name col-4 mr-1"
                          maxLength="120" type="text" value={option.option_name_jp}
                          onChange={(event) => setOption(indexGroup, index, event, 'option_name_jp')}
                        />
                        <input
                          className="option-name col-4 mr-1"
                          maxLength="120" type="text" value={option.option_name_kr}
                          onChange={(event) => setOption(indexGroup, index, event, 'option_name_kr')}
                        />
                        <input
                          className="option-name col-4 mr-1"
                          maxLength="120" type="text" value={option.option_name_id}
                          onChange={(event) => setOption(indexGroup, index, event, 'option_name_id')}
                        />
                        <input
                          className="option-price col-2 mr-3"
                          min="0"
                          type="number"
                          value={option.option_price}
                          onChange={(event) =>
                            setOption(indexGroup, index, event, "option_price")
                          }
                        />
                        <input
                          className="option-default btn-custom ml-1"
                          type="checkbox"
                          name={group.option_group_name}
                          checked={option.is_show_price}
                          onClick={(event) =>
                            setOption(indexGroup, index, event, "is_show_price")
                          }
                        />
                        <input
                          className="option-default btn-custom ml-12"
                          type="checkbox"
                          name={group.option_group_name}
                          checked={option.is_default}
                          onClick={(event) =>
                            setOption(indexGroup, index, event, "is_default")
                          }
                        />
                        <span
                          className="option-delete d-flex ml-6 text-primary btn-custom"
                          onClick={() => deleteOption(indexGroup, index)}
                        >
                          <i className="fa fa-trash mr-3 text-primary"></i>
                          {intl.formatMessage({ id: "104038", defaultMessage: "delete" })}
                        </span>
                      </div>
                    );
                  })}
                  <span className="span-border mb-1 mt-3"></span>
                  <span
                    className="option-delete d-flex text-primary px-5 py-3 btn-custom"
                    onClick={() => addOption(index)}
                  >
                    <i className="fa fa-plus-circle mr-3 text-primary"></i>
                    {intl.formatMessage({
                      id: "104042",
                      defaultMessage: "add option",
                    })}
                  </span>
                  {type === "multi" ? (
                    <div className="">
                      <div className="d-flex align-items-center mb-1 font-weight-bold">
                        <span className="option-name col-3 mr-1">
                          {intl.formatMessage({
                            id: "104044",
                            defaultMessage: "Minimum",
                          })}
                        </span>
                        <span className="option-price col-3">
                          {intl.formatMessage({
                            id: "104045",
                            defaultMessage: "Maximum",
                          })}
                        </span>
                      </div>
                      <div className="d-flex align-items-center mb-1">
                        <input
                          className="col-3 mr-1"
                          type="number"
                          min="0"
                          value={group.option_pick_min}
                          onChange={(event) =>
                            setOptionGroup(index, event, "option_pick_min")
                          }
                        />
                        <input
                          className="col-3 "
                          type="number"
                          min="0"
                          value={group.option_pick_max}
                          onChange={(event) =>
                            setOptionGroup(index, event, "option_pick_max")
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
          }
        })}
        <span className="span-border mb-1 mt-3"></span>
        <span
          className="option-delete d-flex text-primary px-5 py-3 btn-custom"
          onClick={() => addGroup(type)}
        >
          <i className="fa fa-plus-circle mr-3 text-primary"></i>
          {intl.formatMessage({ id: "104017", defaultMessage: "Cancel" })}
        </span>
      </>
    );
  };
  const BindOptionOriginal = (type) => {
    return (
      <>
        {optionTemplate.map((group, index) => {
          let indexGroup = index;
          if (group.type == type) {
            return (
              <div className="option-box" key={index}>
                <div>
                  <div className="d-flex align-items-center">
                    <Switch
                      checked={group.is_active ?? true}
                      onChange={(e) => setOptionGroup(index, e, "is_active")}
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      className="input-cheked-show"
                    />
                    <span className="ml-3">
                      {intl.formatMessage({
                        id: "###",
                        defaultMessage: "เปิด/ปิด option Group นี้",
                      })}
                    </span>
                  </div>
                  <div
                    className={` group-name d-flex align-items-center${group.validate ? " validate" : ""
                      }`}
                  >
                    <div>
                      <span className="mr-5 name">
                        {intl.formatMessage({
                          id: "113121",
                          defaultMessage: "group name TH",
                        })}
                      </span>
                      <input
                        type="text"
                        minLength="100"
                        value={group.option_group_name}
                        onChange={(event) =>
                          setOptionGroup(index, event, "option_group_name")
                        }
                      />
                    </div>
                  </div>
                  <div
                    className={`group-name d-flex align-items-center${group.validate_en ? " validate" : ""
                      }`}
                  >
                    <div>
                      <span className="mr-5 name">
                        {intl.formatMessage({
                          id: "113122",
                          defaultMessage: "group name EN",
                        })}
                      </span>
                      <input
                        type="text"
                        minLength="100"
                        value={group.option_group_name_en}
                        onChange={(event) =>
                          setOptionGroup(index, event, "option_group_name_en")
                        }
                      />
                    </div>
                  </div>
                  <div
                    className={`group-name d-flex align-items-center${group.validate_en ? " validate" : ""
                      }`}
                  >
                    {enable_cn && <div>
                      <span className="mr-5 name">{intl.formatMessage({ id: "103124", defaultMessage: "group name CN" })}</span>
                      <input type="text" minLength="100" value={group.option_group_name_cn} onChange={(event) => setOptionGroup(index, event, "option_group_name_cn")} />
                    </div>}

                    <span
                      className="option-delete d-flex text-primary ml-3 btn-custom"
                      onClick={() => deleteGroup(index)}
                    >
                      <i className="fa fa-trash mr-3 text-primary"></i>
                      {intl.formatMessage({
                        id: "104038",
                        defaultMessage: "delete",
                      })}
                    </span>
                  </div>
                </div>
                <div className="option-list">
                  <div className="d-flex align-items-center mb-1 font-weight-bold">
                    <span className="option-name col-4 mr-1">
                      {intl.formatMessage({
                        id: "###",
                        defaultMessage: "status",
                      })}
                    </span>
                    <span className="option-name col-4 mr-1">
                      {intl.formatMessage({
                        id: "104039",
                        defaultMessage: "Option name th",
                      })}
                    </span>
                    <span className="option-name col-4 mr-1">
                      {intl.formatMessage({
                        id: "103123",
                        defaultMessage: "Option name en",
                      })}
                    </span>
                    {enable_cn && <span className="option-name col-5 mr-1">{intl.formatMessage({ id: "103125", defaultMessage: "Option name cn" })}</span>}
                    <span className="option-price col-3 mr-5">
                      {intl.formatMessage({
                        id: "104040",
                        defaultMessage: "price",
                      })}
                    </span>
                    <span className="option-default">
                      {intl.formatMessage({
                        id: "104041",
                        defaultMessage: "show price",
                      })}
                    </span>
                  </div>
                  {group.options.map((option, index) => {
                    return (
                      <div
                        className="d-flex align-items-center mb-1"
                        key={index}
                      >
                        <div className="option-name col-4 mr-1" maxLength="40">
                          <Switch
                            checked={option.is_active}
                            onChange={(e) => setOption(indexGroup, index, e, "is_active")}
                            inputProps={{ "aria-label": "secondary checkbox" }}
                            className="input-cheked-show"
                          />
                        </div>
                        <input
                          className="option-name col-4 mr-1"
                          maxLength="40"
                          type="text"
                          value={option.option_name}
                          onChange={(event) =>
                            setOption(indexGroup, index, event, "option_name")
                          }
                        />
                        <input
                          className="option-name col-4 mr-1"
                          maxLength="40"
                          type="text"
                          value={option.option_name_en}
                          onChange={(event) =>
                            setOption(
                              indexGroup,
                              index,
                              event,
                              "option_name_en"
                            )
                          }
                        />
                        {enable_cn && <input className="option-name col-5 mr-1" maxLength="50" type="text" value={option.option_name_cn} onChange={(event) => setOption(indexGroup, index, event, 'option_name_cn')} />}
                        <input
                          className="option-price col-3 mr-5"
                          min="0"
                          type="number"
                          value={option.option_price}
                          onChange={(event) =>
                            setOption(indexGroup, index, event, "option_price")
                          }
                        />
                        <input
                          className="option-default btn-custom"
                          type="checkbox"
                          name={group.option_group_name}
                          checked={option.is_show_price}
                          onClick={(event) =>
                            setOption(indexGroup, index, event, "is_show_price")
                          }
                        />
                        <span
                          className="option-delete d-flex ml-3 text-primary btn-custom"
                          onClick={() => deleteOption(indexGroup, index)}
                        >
                          <i className="fa fa-trash mr-3 text-primary"></i>
                          {intl.formatMessage({
                            id: "104038",
                            defaultMessage: "delete",
                          })}
                        </span>
                      </div>
                    );
                  })}
                  <span className="span-border mb-1 mt-3"></span>
                  <span
                    className="option-delete d-flex text-primary px-5 py-3 btn-custom"
                    onClick={() => addOption(index)}
                  >
                    <i className="fa fa-plus-circle mr-3 text-primary"></i>
                    {intl.formatMessage({
                      id: "104042",
                      defaultMessage: "add option",
                    })}
                  </span>
                  {type === "multi" ? (
                    <div className="">
                      <div className="d-flex align-items-center mb-1 font-weight-bold">
                        <span className="option-name col-3 mr-1">
                          {intl.formatMessage({
                            id: "104044",
                            defaultMessage: "Minimum",
                          })}
                        </span>
                        <span className="option-price col-3">
                          {intl.formatMessage({
                            id: "104045",
                            defaultMessage: "Maximum",
                          })}
                        </span>
                      </div>
                      <div className="d-flex align-items-center mb-1">
                        <input
                          className="col-3 mr-1"
                          type="number"
                          min="0"
                          value={group.option_pick_min}
                          onChange={(event) =>
                            setOptionGroup(index, event, "option_pick_min")
                          }
                        />
                        <input
                          className="col-3 "
                          type="number"
                          min="0"
                          value={group.option_pick_max}
                          onChange={(event) =>
                            setOptionGroup(index, event, "option_pick_max")
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
          }
        })}
        <span className="span-border mb-1 mt-3"></span>
        <span
          className="option-delete d-flex text-primary px-5 py-3 btn-custom"
          onClick={() => addGroup(type)}
        >
          <i className="fa fa-plus-circle mr-3 text-primary"></i>
          {intl.formatMessage({ id: "104017", defaultMessage: "Cancel" })}
        </span>
      </>
    );
  };

  const setOptionGroup = (index, event, group) => {
    var newState = [...optionTemplate];
    if (group == "is_active") {
      if (newState[index][group] === undefined) {
        newState[index][group] = newState[index][group] ?? true;
        newState[index][group] = !newState[index][group];
      } else {
        newState[index][group] = !newState[index][group];
      }
    } else {
      newState[index][group] = event.target.value;
      newState[index]["validateGroup"] = false;

    }
    setOptionTemplate(newState);
  };

  const setOption = (indexGroup, index, event, option) => {
    let data;
    if (option === "is_active") {
      var currentState = [...optionTemplate];
      data = !currentState[indexGroup].options[index][option];
    } else if (option === "is_default") {
      data = event.target.checked;
      if (data) {
        var newState = [...optionTemplate];
        newState[indexGroup].options.forEach((opt, idx) => {
          if (idx !== index) {
            opt.is_default = false;
          }
        });
      }
    } else if (option != 'is_show_price') {
      data = event.target.value;
    } else {
      data = event.target.checked;
    }
    var newState = [...optionTemplate];
    newState[indexGroup].options[index][option] = data;

    if (option === "option_name") {
      newState[indexGroup].options[index].validateOption = !validateOptionName(data);
    }

    setOptionTemplate(newState);
  }

  const setOption_original = (indexGroup, index, event, option) => {
    let data;
    if (option === "is_active") {
      var currentState = [...optionTemplate];
      data = !currentState[indexGroup].options[index][option];
    } else if (option != 'is_show_price') {
      data = event.target.value;
    } else {
      data = event.target.checked;
    }
    var newState = [...optionTemplate];
    newState[indexGroup].options[index][option] = data;

    if (option === "option_name") {
      newState[indexGroup].options[index].validateOption = !validateOptionName(data);
    }

    // if (option === "is_default") {
    //   data = event.target.checked;
    // }

    setOptionTemplate(newState);
  }

  const validateOptionGroupName = (optionGroupName) => {
    return optionGroupName && optionGroupName.trim() !== "";
  }

  const validateOptionName = (optionName) => {
    return optionName && optionName.trim() !== "";
  }

  const addOption = (index) => {
    // const can_edit = (template_id === '' || template_id === undefined) || cloneTemplate;
    // if (can_edit) {
    const newState = [...optionTemplate];
    const optionGroupName = newState[index]["option_group_name"];
    const options = newState[index]["options"];

    const isValidGroupName = validateOptionGroupName(optionGroupName);
    const isValidOptionNames = options.every(option => validateOptionName(option.option_name));

    if (isValidGroupName && isValidOptionNames) {
      newState[index]["validateGroup"] = false;
      newState[index]["options"].forEach(option => {
        if (option.option_name === '') {
          option.validateOption = true;
        } else {
          option.validateOption = false;
        }
      });
      newState[index]["options"].push({
        option_id: null,
        option_name: '',
        option_name_en: '',
        option_name_cn: '',
        option_name_jp: '',
        option_name_kr: '',
        option_name_id: '',
        option_price: 0,
        is_show_price: true,
        is_active: false,
        validateOption: false
      });
    } else {
      newState[index]["validateGroup"] = !isValidGroupName;
      newState[index]["options"].forEach(option => {
        option.validateOption = !validateOptionName(option.option_name);
      });
    }

    setOptionTemplate(newState);
    // }
  }

  const addOptionProblem = (index) => {
    const can_edit = (template_id === '' || template_id === undefined) || cloneTemplate;
    if (can_edit) {
      const newState = [...optionTemplate];
      const optionGroupName = newState[index]["option_group_name"];

      const isValidGroupName = validateOptionGroupName(optionGroupName);

      if (isValidGroupName) {
        newState[index]["validate"] = false
        newState[index]["options"].push({
          id: null,
          option_name: '',
          option_name_en: '',
          option_name_cn: '',
          option_name_jp: '',
          option_name_kr: '',
          option_name_id: '',
          option_price: 0,
          is_show_price: true,
          is_active: false
        });
      } else {
        newState[index]["validate"] = true;
      }

      setOptionTemplate(newState)
    }
  }

  const addOptionOriginal = (index) => {
    var newState = [...optionTemplate];
    if (newState[index]["option_group_name"] !== "") {
      newState[index]["validate"] = false
      newState[index]["options"].push({ id: null, option_name: '', option_price: 0, is_show_price: true, is_active: true })
    } else {
      newState[index]["validate"] = true
    }
    setOptionTemplate(newState)
  }

  const addGroupV1 = (type) => {
    // const can_edit = (template_id === '' || template_id === undefined) || cloneTemplate;
    // if (can_edit) {
    const oldOptionTemplate = [...optionTemplate];
    console.log("oldOptionTemplate ==>", oldOptionTemplate)
    if (oldOptionTemplate.length > 0) {
      oldOptionTemplate.forEach((group) => {
        console.log("group.option_group_name ==>", group.option_group_name)
        if (group.option_group_name === '') {
          group.validateGroup = true;
        } else {
          group.validateGroup = false;
        }
      })
      if (oldOptionTemplate.some(group => group.option_group_name === '')) {
        AlertError("Please fill in the group name.")
        return;
      }
      const newState = []
      newState.push(
        {
          id: null,
          option_group_name: '',
          option_group_name_en: '',
          option_group_name_cn: '',
          option_group_name_jp: '',
          option_group_name_kr: '',
          option_group_name_id: '',
          options: [],
          option_pick_min: type === "multi" ? 0 : 1,
          option_pick_max: type === "multi" ? 0 : 1,
          type,
          validateGroup: false,
          validate_en: false,
          is_active: false
        }
      )
      setOptionTemplate([...oldOptionTemplate, ...newState]);
    }
    console.log("newOptionTemplate ==>", optionTemplate)
  }

  const addGroup = (type) => {
    const newState = [...optionTemplate];
    console.log("newState ==>", newState);

    const newGroup = {
      option_group_id: null,
      option_group_name: '',
      option_group_name_en: '',
      option_group_name_cn: '',
      option_group_name_jp: '',
      option_group_name_kr: '',
      option_group_name_id: '',
      options: [],
      option_pick_min: type === "multi" ? 0 : 1,
      // option_pick_max: type === "multi" ? 0 : 1,
      option_pick_max: 1,
      type,
      validateGroup: false,
      validate_en: false,
      is_active: false
    };

    const isValidGroupName = newState.every(group => validateOptionGroupName(group.option_group_name));
    const isValidOptionNames = newState.every(group => group.options.every(option => validateOptionName(option.option_name)));

    if (isValidGroupName && isValidOptionNames) {
      newGroup.validateGroup = false;
      newGroup.options.forEach(option => {
        option.validateOption = option.option_name === '';
      });
      newState.push(newGroup);
      setOptionTemplate(newState);
      console.log("newOptionTemplate add newGroup ==>", optionTemplate);
    } else {
      newState.forEach(group => {
        group.validateGroup = !validateOptionGroupName(group.option_group_name);
        if (group.options.some(option => option.option_name === '')) {
          group.options.forEach(option => {
            option.validateOption = option.option_name === '';
          });
        }
        setOptionTemplate(newState);
        console.log("newOptionTemplate validate ==>", optionTemplate);
      })
    }
  }

  // useEffect(() => {
  //   console.log("optionTemplate ==>", optionTemplate);
  // }, [optionTemplate]);

  const addGroup_problem = (type) => {
    let can_edit = (template_id === '' || template_id === undefined) || cloneTemplate;
    if (can_edit) {
      const newState = [{
        id: null,
        option_group_name: '',
        option_group_name_en: '',
        option_group_name_cn: '',
        option_group_name_jp: '',
        option_group_name_kr: '',
        option_group_name_id: '',
        options: [],
        option_pick_min: type === "multi" ? 0 : 1,
        option_pick_max: type === "multi" ? 0 : 1,
        type,
        validate: false,
        validate_en: false,
        is_active: false
      }];
      setOptionTemplate([...optionTemplate, ...newState])
    }
  }

  const addGroupOriginal = (type) => {
    var newState = []
    newState.push({
      id: null,
      option_group_name: '',
      options: [],
      option_pick_min: type === "multi" ? 0 : 1,
      option_pick_max: type === "multi" ? 0 : 1,
      type,
      validate: false
    })
    setOptionTemplate([...optionTemplate, ...newState])
  }

  const deleteGroup = (index) => {
    var newState = [...optionTemplate];
    newState.splice(index, 1)
    setOptionTemplate(newState)
  }
  const deleteOption = (indexGroup, index) => {
    var newState = [...optionTemplate];
    newState[indexGroup].options.splice(index, 1)
    setOptionTemplate(newState)
  }

  const clone = () => {
    let boo = !cloneTemplate
    // console.log('clone', boo);
    setCloneTemplate(boo)
    if (boo) {
      setTemplate_id('')
    }

  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handlePass = (data) => {
    console.log('handlePass');
    if (data === 'save') {
      getGroup()
    }
    handleClose()
  }

  return (
    <>
      <Card>
        <CardHeader title={intl.formatMessage({ id: "112047", defaultMessage: "Edit Menu Buffet" })}></CardHeader>
        <CardBody>
          <div className="form-inline" style={{ marginTop: "25px", marginBottom: "25px" }}>
            <p style={{ marginTop: "25px", float: "left", width: "20%" }}>
              <span style={{ color: "red" }}>*</span>
              {intl.formatMessage({
                id: "104015",
                defaultMessage: "group name",
              })}
              :{" "}
            </p>
            <div
              className="d-flex"
              style={{
                width: "530px",
              }}
            >
              <select
                as="select"
                style={{
                  flexGrow: "1",
                  height: "38px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={group_id}
                onChange={handleChangeGroup}
              >
                <option value="">
                  {intl.formatMessage({
                    id: "104035",
                    defaultMessage: "please select",
                  })}
                </option>
                {group &&
                  group.map((result, index) => {
                    return (
                      <option key={index} value={result.id}>
                        {result.group_name}
                      </option>
                    );
                  })}
              </select>
              <Button
                variant="light"
                className="ml-3 text-primary"
                onClick={handleShow}
              >
                <i className="fa fa-plus-circle mr-3 text-primary" />
                {intl.formatMessage({
                  id: "104017",
                  defaultMessage: "add group",
                })}
              </Button>

              <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Body>
                  <CreateMenuGroupV3 type={"dialog"} handle={handlePass} />
                </Modal.Body>
                {/* <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button variant="primary" onClick={handleClose}>
                    Save
                  </Button>
                </Modal.Footer> */}
              </Modal>
            </div>
          </div>

          {/* Thai name */}
          <div className="form-inline" style={{ marginBottom: "25px" }}>

            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              <span style={{ color: "red" }}>
                *
              </span>
              {intl.formatMessage({ id: "104018", defaultMessage: "Thai menu name" })}
            </p>
            <input
              value={item_name}
              name="item_name"
              maxLength="100"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(e) => setItem_name(e.target.value)}
            />
          </div>
          {/* Thai name */}
          {/* Thai description */}
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({ id: "104020", defaultMessage: "Menu details" })}
            </p>
            <input
              value={description}
              name="description"
              maxLength="500"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(e) => setDescription(e.target.value)
              }
            />
          </div>
          {/* Thai description */}
          {/* English name  */}
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({ id: "104019", defaultMessage: "English group name" })}
            </p>
            <input
              value={item_name_en}
              name="item_name_en"
              maxLength="100"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(e) => setItem_name_en(e.target.value)}
            />
          </div>
          {/* English name  */}
          {/* English description */}
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({ id: "104021", defaultMessage: "English menu details" })}
            </p>
            <input
              value={description_en}
              name="description_en"
              maxLength="500"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(e) => setDescription_en(e.target.value)}
            />
          </div>
          {/* English description */}

          {/* More language Start */}
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <Accordion style={{
              // width: "100%",
              maxWidth: "725px",
              marginTop: "15px", float: "left", borderRadius: "10px",
              borderWidth: "0",
              // width: "700px" 
            }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {intl.formatMessage({ id: "113523", defaultMessage: "More languages" })}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="container pb-6" style={{ border: "1px solid", borderRadius: "10px", borderColor: "#CECDCD", paggingBottom: "20px", paddingTop: "20px" }}>
                  {/* Chinese badges */}
                  <div className="form-inline" >
                    <span className="badge badge-pill badge-secondary p-4"
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "bold"
                      }}>
                      {intl.formatMessage({ id: "113526", defaultMessage: "Chinese" })}
                    </span>
                  </div>
                  {/* Chinese badges */}
                  {/* Chinese name  */}
                  <div className="form-inline">
                    <p style={{ marginTop: "15px", float: "left", width: "150px" }}>
                      {intl.formatMessage({ id: "103127", defaultMessage: "Chinese menu name" })}
                    </p>
                    <input
                      value={item_name_cn}
                      name="item_name_cn"
                      maxLength="100"
                      style={{
                        height: "38px",
                        width: "470px",
                        backgroundColor: "#F4F6F9",
                        borderRadius: "5px",
                        borderWidth: "0",
                      }}
                      onChange={(e) => setItem_name_cn(e.target.value)
                      }
                    />
                  </div>
                  {/* Chinese name  */}
                  {/* Chinese description */}
                  <div className="form-inline" >
                    <p style={{ marginTop: "15px", float: "left", width: "150px" }}>
                      {intl.formatMessage({ id: "103128", defaultMessage: "Chinese menu details" })}
                    </p>
                    <input
                      value={description_cn}
                      name="description_cn"
                      maxLength="100"
                      style={{
                        height: "38px",
                        width: "470px",
                        backgroundColor: "#F4F6F9",
                        borderRadius: "5px",
                        borderWidth: "0",
                      }}
                      onChange={(e) => setDescription_cn(e.target.value)
                      }
                    />
                  </div>
                  {/* Chinese description */}
                  {/* Japanese badges */}
                  <div className="form-inline mt-3" >
                    <span className="badge badge-pill badge-secondary p-4"
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "bold"
                      }}>
                      {intl.formatMessage({ id: "113527", defaultMessage: "Japanese" })}
                    </span>
                  </div>
                  {/* Japanese badges */}
                  {/* Japanese name  */}
                  <div className="form-inline" >
                    <p style={{ marginTop: "15px", float: "left", width: "150px" }}>
                      {intl.formatMessage({ id: "113517", defaultMessage: "Japanese menu name" })}
                    </p>
                    <input
                      value={item_name_jp}
                      name="item_name_jp"
                      maxLength="100"
                      style={{
                        height: "38px",
                        width: "470px",
                        backgroundColor: "#F4F6F9",
                        borderRadius: "5px",
                        borderWidth: "0",
                      }}
                      onChange={(e) => setItem_name_jp(e.target.value)
                      }
                    />
                  </div>
                  {/* Japanese name  */}
                  {/* Japanese description */}
                  <div className="form-inline">
                    <p style={{ marginTop: "15px", float: "left", width: "150px" }}>
                      {intl.formatMessage({ id: "113518", defaultMessage: "Japanese menu details" })}
                    </p>
                    <input
                      value={description_jp}
                      name="description_jp"
                      maxLength="100"
                      style={{
                        height: "38px",
                        width: "470px",
                        backgroundColor: "#F4F6F9",
                        borderRadius: "5px",
                        borderWidth: "0",
                      }}
                      onChange={(e) => setDescription_jp(e.target.value)
                      }
                    />
                  </div>
                  {/* Japanese description */}
                  {/* Korean badges */}
                  <div className="form-inline mt-3" >
                    <span className="badge badge-pill badge-secondary p-4"
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "bold"
                      }}>
                      {intl.formatMessage({ id: "113528", defaultMessage: "Korean" })}
                    </span>
                  </div>
                  {/* Korean badges */}
                  {/* Korean name  */}
                  <div className="form-inline" >
                    <p style={{ marginTop: "15px", float: "left", width: "150px" }}>
                      {intl.formatMessage({ id: "113519", defaultMessage: "Korean menu name" })}
                    </p>
                    <input
                      value={item_name_kr}
                      name="item_name_kr"
                      maxLength="100"
                      style={{
                        height: "38px",
                        width: "470px",
                        backgroundColor: "#F4F6F9",
                        borderRadius: "5px",
                        borderWidth: "0",
                      }}
                      onChange={(e) => setItem_name_kr(e.target.value)
                      }
                    />
                  </div>
                  {/* Korean name  */}
                  {/* Korean description */}
                  <div className="form-inline">
                    <p style={{ marginTop: "15px", float: "left", width: "150px" }}>
                      {intl.formatMessage({ id: "113520", defaultMessage: "Korean menu details" })}
                    </p>
                    <input
                      value={description_kr}
                      name="description_kr"
                      maxLength="100"
                      style={{
                        height: "38px",
                        width: "470px",
                        backgroundColor: "#F4F6F9",
                        borderRadius: "5px",
                        borderWidth: "0",
                      }}
                      onChange={(e) => setDescription_kr(e.target.value)
                      }
                    />
                  </div>
                  {/* Korean description */}
                  {/* Indian badges */}
                  <div className="form-inline mt-3" >
                    <span className="badge badge-pill badge-secondary p-4"
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "bold"
                      }}>
                      {intl.formatMessage({ id: "113529", defaultMessage: "Indian" })}
                    </span>
                  </div>
                  {/* Indian badges */}
                  {/* Indian name  */}
                  <div className="form-inline">
                    <p style={{ marginTop: "15px", float: "left", width: "150px" }}>
                      {intl.formatMessage({ id: "113521", defaultMessage: "Indian menu name" })}
                    </p>
                    <input
                      value={item_name_id}
                      name="item_name_id"
                      maxLength="100"
                      style={{
                        height: "38px",
                        width: "470px",
                        backgroundColor: "#F4F6F9",
                        borderRadius: "5px",
                        borderWidth: "0",
                      }}
                      onChange={(e) => setItem_name_id(e.target.value)
                      }
                    />
                  </div>
                  {/* Indian name  */}
                  {/* Indian description */}
                  <div className="form-inline" >
                    <p style={{ marginTop: "15px", float: "left", width: "150px" }}>
                      {intl.formatMessage({ id: "113522", defaultMessage: "Indian menu details" })}
                    </p>
                    <input
                      value={description_id}
                      name="description_id"
                      maxLength="100"
                      style={{
                        height: "38px",
                        width: "470px",
                        backgroundColor: "#F4F6F9",
                        borderRadius: "5px",
                        borderWidth: "0",
                      }}
                      onChange={(e) => setDescription_id(e.target.value)
                      }
                    />
                  </div>
                  {/* Indian description */}
                </div>
                {/* </CardBody> */}
              </AccordionDetails>
            </Accordion>
          </div>
          {/* More language End */}

          {/* Receipt Start */}
          <div className="form-inline" style={{ marginBottom: "25px" }}>

            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              <span style={{ color: "red" }}>
                *
              </span>
              {intl.formatMessage({ id: "112020", defaultMessage: "Menu Name on The Receipt" })}:{" "}
            </p>
            <input
              name="description_receipt"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={description_receipt}
              onChange={(description_receipt) =>
                setDescription_receipt(description_receipt.target.value)
              }
            />
          </div>
          {/* Receipt End */}
          <div className="form-inline" style={{ marginBottom: '25px' }}>
            <p style={{ marginTop: '15px', float: 'left', width: '20%' }}>
              <span style={{ color: "red" }}>
                *
              </span>
              {intl.formatMessage({ id: "112021", defaultMessage: "Display Order" })}:{' '}
            </p>
            <input
              name="displayOrder"
              type="number"
              style={{
                height: '38px',
                width: '530px',
                backgroundColor: '#F4F6F9',
                borderRadius: '5px',
                borderWidth: '0'
              }}
              value={displayOrder}
              onChange={displayOrder =>
                setDisplayOrder(displayOrder.target.value)
              }
            />
          </div>
          {/* Image Start */}
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({ id: "112035", defaultMessage: "Image" })} : <br></br>
              (512*512)
            </p>
            <div
              className="d-flex align-items-center"
              style={{
                width: "530px",
              }}>
              <input
                name="item_image_path"
                style={{
                  flexGrow: "1",
                  height: "38px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                  paddingTop: "5px",
                  paddingBottome: "5px",
                }}
                disabled={loading.upload}
                value={item_image_path}
              />
              <label
                className={`btn btn-primary btn-file wbt px-9 py-4 my-3 ${loading.upload ? "disabled" : ""}`}
                style={{ whiteSpace: "nowrap", width: "fit-content", }}
              >
                <span>
                  {intl.formatMessage({ id: "104025", defaultMessage: "Upload/Edit", })}
                </span>
                {loading.upload && <span className="ml-3 spinner spinner-white"></span>}
                <input type="file" onChange={handleChangeItem_image_path} style={{ display: "none" }} />
              </label>
              {/* <span className="btn btn-primary btn-file wbt pt-4"
                style={{
                  whiteSpace: "nowrap",
                  width: "fit-content"
                }}>
                {intl.formatMessage({ id: "112036", defaultMessage: "Upload/Edit" })}
                <input type="file" onChange={handleChangeItem_image_path} />
              </span> */}

              <button
                type="button"
                className="btn btn-danger wbt px-8 py-4 my-3"
                // className="btn btn-danger wbt"
                onClick={removeImageItem_image_path}
                disabled={loading.upload}
              >
                {intl.formatMessage({ id: "112037", defaultMessage: "Delete" })}
              </button>
            </div>
          </div>

          <div
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              width: "50%",
            }}
          >
            {stateItem_image_path.previewItem_image_path ? (
              <img
                style={{
                  width: "300px",
                  height: "300px",
                }}
                src={stateItem_image_path.previewItem_image_path}
              />
            ) : item_image_path ? (
              <img
                style={{
                  width: "300px",
                  height: "300px",
                }}
                src={item_image_path}
              />
            ) : null}
          </div>
          {stateItem_image_path.imageURLItem_image_path ? (
            <CropperImage
              image={stateItem_image_path.imageURLItem_image_path}
              handleCancel={handleCancelUploadItem_image_path}
              handleUpload={handleImageUploadItem_image_path}
              scale={1}
            />
          ) : (
            ""
          )}
          {/* Image End */}

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({ id: "112038", defaultMessage: "Unit" })}:{" "}
            </p>
            <input
              name="uom"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={uom}
              onChange={(uom) => setUom(uom.target.value)}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              <span style={{ color: "red" }}>
                *
              </span>
              {intl.formatMessage({ id: "112039", defaultMessage: "Price" })}:{" "}
            </p>
            <input
              name="price"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={price}
              onChange={(price) => setPrice(price.target.value)}
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              <span style={{ color: "red" }}>
                *
              </span>
              {intl.formatMessage({ id: "112040", defaultMessage: "Printer Zone" })} :{" "}
            </p>

            {zoneprinter_data.length > 0 ? (
              <div>
                <Autocomplete
                  id="zone printer"
                  options={zoneprinter_data}
                  getOptionLabel={(option) => intl.locale == 'th' ? option.zone_name : option.zone_name_en}
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  value={printer_zone_data ? printer_zone_data : ""}
                  onChange={handleChangezoneprinter}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.inputColor}
                      style={{
                        borderWidth: 0,
                      }}
                      label={intl.formatMessage({ id: "112041", defaultMessage: "Zone Printer" })}
                    />
                  )}
                />
              </div>
            ) : null}

          </div>

          <div className="form-inline d-none" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              ตัวเลือก:{" "}
            </p>
            <div
              style={{
                width: "530px",
              }}
            >
              <div className="d-flex align-items-center">
                <Switch
                  checked={changeOption}
                  onChange={() => handleChangeOption()}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  className="input-cheked-show"
                />
                <span className="ml-3">มีตัวเลือกสำหรับเมนูอาหาร</span>
              </div>
              <div className={`box-cheked-show${changeOption ? ' d-block' : ''}`}>
                <div className="template mb-3 d-flex align-items-center"
                  style={{
                    width: "530px",
                  }}>
                  <span className="mr-3">
                    รูปแบบ
                  </span>
                  <select
                    as="select"
                    style={{
                      flexGrow: "1",
                      height: "38px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                    }}
                    value={template_id}
                    onChange={handleChangeTemplate}
                  >
                    <option value="">กรุณาเลือก</option>
                    {template &&
                      template.map((result, index) => {
                        return (
                          <option key={index} value={result.id}>
                            {result.template_name}
                          </option>
                        );
                      })}
                  </select>
                  <div alt="clone template" className={`button-clone d-none${(template_id !== '' && template_id !== undefined) ? ' d-flex' : ''}${cloneTemplate ? ' active' : ''}`}>
                    <i className="fa fa-clone" onClick={() => clone()}></i>
                  </div>
                </div>
                <div className="single-choise mb-4">
                  <span className="title bg-light">
                    ตัวเลือกแบบตัวเลือกเดียว
                  </span>
                  {BindOption('single')}
                </div>
                <div className="multi-choise">
                  <span className="title bg-light">
                    ตัวเลือกแบบหลายตัวเลือก
                  </span>
                  {BindOption('multi')}
                </div>
              </div>
            </div>
          </div>
          {/* ------------------option---------------- */}
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({ id: "104032", defaultMessage: "Options" })}:{" "}
            </p>
            <div
              style={{
                width: "530px",
              }}
            >
              <div className="d-flex align-items-center">
                <Switch
                  checked={changeOption}
                  onChange={() => handleChangeOption()}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  className="input-cheked-show"
                />
                <span className="ml-3">
                  {intl.formatMessage({
                    id: "104033",
                    defaultMessage: "Options for the food menu.",
                  })}
                </span>*
              </div>
              <div
                className={`box-cheked-show${changeOption ? " d-block" : ""}`}
              >
                <div
                  className="template mb-3 d-flex align-items-center"
                  style={{
                    width: "530px",
                  }}
                >
                  <span className="mr-3">
                    {intl.formatMessage({
                      id: "104034",
                      defaultMessage: "pattern",
                    })}
                  </span>
                  <select
                    as="select"
                    style={{
                      flexGrow: "1",
                      height: "38px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                    }}
                    value={template_id}
                    onChange={handleChangeTemplate}
                  >
                    <option value="">
                      {intl.formatMessage({
                        id: "104016",
                        defaultMessage: "please select",
                      })}
                    </option>
                    {template &&
                      template.map((result, index) => {
                        return (
                          <option key={index} value={result.id}>
                            {result.template_name}
                          </option>
                        );
                      })}
                  </select>
                  <div
                    alt="clone template"
                    className={`button-clone d-none${template_id !== "" && template_id !== undefined
                      ? " d-flex"
                      : ""
                      }${cloneTemplate ? " active" : ""}`}
                  >
                    <i className="fa fa-clone" onClick={() => clone()}></i>
                  </div>
                </div>

                <div className="d-flex align-items-center">
                  <Switch
                    checked={changeName}
                    onChange={() => handleChangeNameOption()}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    className="input-cheked-show"
                  />
                  <span className="ml-3">กำหนดชื่อรูปแบบ</span>
                </div>
                <div
                  className={`template name mb-3 align-items-center${changeName ? " d-flex" : ""
                    }`}
                  style={{
                    width: "530px",
                  }}
                >
                  <span className="mr-3">ชื่อรูปแบบ</span>
                  <input
                    name="template name"
                    type="text"
                    min="0"
                    style={{
                      height: "38px",
                      flexGrow: "1",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                    }}
                    onChange={(templateName) =>
                      setTemplateName(templateName.target.value)
                    }
                  />
                </div>

                <div className="single-choise mb-4">
                  <span className="title bg-light">
                    {intl.formatMessage({
                      id: "104036",
                      defaultMessage: "Single option",
                    })}
                  </span>
                  {BindOption("single")}
                </div>
                <div className="multi-choise">
                  <span className="title bg-light">
                    {intl.formatMessage({
                      id: "104043",
                      defaultMessage: "Multiple Option",
                    })}
                  </span>
                  {BindOption("multi")}
                </div>
              </div>
            </div>
          </div>

          {/* <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              {intl.formatMessage({ id: "113263", defaultMessage: "Time couting" })}:{" "}
            </p>
            <Switch
              checked={enableTimeCounting}
              onChange={(e) => setEnableTimeCounting(e.target.checked)}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div> */}

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({ id: "113264", defaultMessage: "Time period (Minute)" })}:{" "}
            </p>
            <input
              name="time_period"
              type="number"
              min="0"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(time_period) => setTimePeriod(time_period.target.value)}
              value={timePeriod}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              {intl.formatMessage({ id: "112042", defaultMessage: "Enable" })}:{" "}
            </p>
            <Switch
              checked={state}
              onChange={() => handleChangeSwitch()}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              {intl.formatMessage({ id: "113493", defaultMessage: "Buffet Package" })}:{" "}
            </p>
            <Switch
              checked={buffet_package}
              onChange={() => handleChangeSwitchPackage()}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div>
            <Link to="/MenuBuffet">
              <button
                type="button"
                // className="btn btn-secondary wbt mr-5"
                className="btn btn-secondary font-weight-bold px-9 py-4 my-3 mr-5"
                disabled={loading.submit}
                onClick={() => localStorage.removeItem('edit')}
              >
                {intl.formatMessage({ id: "112043", defaultMessage: "Cancel" })}
              </button>
            </Link>
            <button
              type="submit"
              // className={`${loading ? "spinner spinner-sm spinner-primary btn btn-light" : "btn btn-primary"}`}
              className="btn btn-primary font-weight-bold px-9 py-4 my-3"
              disabled={loading.submit}
              // onClick={() => onSubmit()}
              onClick={onSubmit}
            >
              <span>
                {intl.formatMessage({ id: "112044", defaultMessage: "Save" })}
              </span>
              {loading.submit && <span className="ml-3 spinner spinner-white"></span>}
            </button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
