import React, { useState, useEffect } from "react";
import { Stepper, Step, StepLabel } from "@material-ui/core";
import { useSubheader } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { useHistory, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { baseURL } from "../../../service/API";
import BootstrapTable from "react-bootstrap-table-next";
import axios from "axios";
import Swal from "sweetalert2";
import checkForbiddenSymbols from "../../../utils/regex";
import "../../../pages/component.css";
import { roleCheck } from "../../../service/role";

export const ViewTransferRequest = () => {
  const intl = useIntl();
  const suhbeader = useSubheader();
  const history = useHistory();
  const location = useLocation();
  const [deliveryNote, setDeliveryNote] = useState(null);
  const [confirmQty, setConfirmQty] = useState({});
  const { row } = location.state;
  const [regexError, setRegexError] = useState("");
  const [reason, setReason] = useState('')
  const [status, setStatus] = useState('')
  const [statusApp, setStatusApp] = useState('')
  const [isMainBranch, setIsMainBranch] = useState(false)
  const [campaigns_id_headder, setCampaigns_id_headder] = useState();
  const [roleList, setRoleList] = useState([]);
  suhbeader.setTitle(
    intl.formatMessage({ id: "113585", defaultMessage: "Transfer Request" })
  );
  const checkAuth = async () => {
    const data = await roleCheck('TRANSFER_REQUEST');
    setRoleList(data)
    console.log("data to setRoleList ->", data)
    if (!data.includes('VIEW')) {
      history.push('/auth/login')
    }
    let value = 1
    if (localStorage.getItem("pagePrinterZone") != null) {
      value = localStorage.getItem("pagePrinterZone")
    }
    let campaigns_id_headders = localStorage.getItem("campaigns_id_headder")
    setCampaigns_id_headder(campaigns_id_headders);
  }

  useEffect(() => {
    checkAuth();

  }, []);

  useEffect(() => {
    const fetchDeliveryNote = async () => {
      try {
        const response = await axios.get(`${baseURL}/deliverynote/detailBO/${row.id}`, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("Token")}`,
          },
        });
        setDeliveryNote(response.data.data);
        console.log("response.data.data", response.data);
        getStatus(response.data.data.status);
        setStatusApp(response.data.data.status_app);
        setIsMainBranch(response.data.is_main_branch)
      } catch (error) {
        console.error("Error fetching delivery note:", error);
      }
    };

    fetchDeliveryNote();
  }, [row.id]);

  const handleVerify = async (method) => {
    const token = localStorage.getItem("Token");
    const isCancel = method === "cancel";
    const swalTitle = isCancel ? "Confirm Decline?" : "Confirm Approve?";
    const swalText = isCancel
      ? "Please provide a reason for declining this request."
      : "Transfer Order will be created with this information";
  
    const data = deliveryNote.deliveryNoteDetail.map((item) => ({
      material_id: item.material_id,
      material_name: item.inventory?.bom_material?.name || "Unknown",
      unit: item.unit,
      unit_id: item.unit_id,
      qty: item.qty,
    }));
  
    const result = await Swal.fire({
      title: swalTitle,
      text: swalText,
      icon: "question",
      input: "text",
      inputValue: reason,
      inputPlaceholder: isCancel
        ? "Enter your reason (Required)"
        : "Enter your reason (Optional)",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: {
        validationMessage: "swal-validation-message",
      },
      inputValidator: (value) => {
        if (isCancel && !value) {
          return "Reason is required for declining.";
        }
        if (checkForbiddenSymbols(value)) {
          return 'เครื่องหมาย = < > ! ; \' " & |  ไม่ได้รับอนุญาตให้ใช้';
        }
        return null;
      },
    });
  
    if (result.isConfirmed && !regexError) {
      const note = result.value || "-";
      console.log("data ->", data);
      console.log("note ->", note);
  
      try {
        await axios.post(
          `${baseURL}/deliveryNote/verifyBO/${deliveryNote.id}?method=${method}`,
          {
            send_branch_id: deliveryNote.send_branch_id,
            received_branch_id: deliveryNote.received_branch_id,
            note: note,
            data: data,
          },
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
  
        Swal.fire(
          "Success!",
          isCancel
            ? "The delivery request has been declined."
            : "The delivery request has been confirmed.",
          "success"
        );
        history.push("/TransferRequest");
      } catch (err) {
        console.error("Error:", err);
        Swal.fire(
          "Failed!",
          isCancel
            ? "Your delivery request could not be declined."
            : "Your delivery request could not be confirmed.",
          "error"
        );
      }
    }
  };


  const handleConfirmQtyChange = (itemId, value) => {
    setConfirmQty((prev) => ({
      ...prev,
      [itemId]: value,
    }));
  };

  const getStatus = (status) => {
    switch (status) {
      case 'CANCELED':
        setStatus('การขนย้ายไม่สำเร็จ');
        break;
      case 'COMPLETED':
        setStatus('การขนย้ายเสร็จสิ้น');
        break;
      case 'PENDING':
        setStatus('เริ่มต้นการขนย้าย');
        break;
      case 'DESTINATION_ACCEPTED':
        setStatus('แอพตอบตกลง');
        break;
      case 'DESTINATION_CANCELED':
        setStatus('แอพตอบปฏิเสธ');
        break;
      case 'PENDING':
        setStatus('เริ่มต้นการขนย้าย');
        break;
      default:
        setStatus('สถานะไม่ทราบ');
        break;
    }
  };

  const columns = [
    {
      dataField: "no",
      text: "No.",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerStyle: { width: "20%" },
    },
    {
      dataField: "inventory.bom_material.name",
      text: "Name",
      formatter: (cell, row) => row.inventory ? row.inventory?.bom_material?.name : 'No Inventory',
      headerStyle: { width: "20%" },
    },
    {
      dataField: "qty",
      text: "Quantity",
      headerStyle: { width: "20%" },
    },
    {
      dataField: "inventory.bom_material.major_unit_name",
      text: "Unit",
      formatter: (cell, row) => row.unit ? row.unit : 'No Inventory',
      headerStyle: { width: "30%" },
    },
    
  ];

  if (!deliveryNote) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Card>
        <CardHeader
          title={intl.formatMessage({
            id: "113586",
            defaultMessage: "View Transfer Request Detail",
          })}
        >
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <button
              type="button"
              className="btn btn-secondary" style={{ display: "flex", justifyContent: "flex-end", backgroundColor: "#3699FF", color: "white" }}
              onClick={() => history.push("/TransferRequest")}
            >
              {intl.formatMessage({ id: "113390", defaultMessage: "Back" })}
            </button>
          </div>
        </CardHeader>
        <CardBody>
          <div className="container">

            <div className="delivery-note-value" style={{ marginBottom: "25px", height: "45px" }}>
              <p style={{ marginTop: "15px", marginBottom: "0px", float: "left", width: "20%" }}>
                {intl.formatMessage({
                  id: "113567",
                  defaultMessage: "Origin Branch",
                })} :
              </p>
              <p style={{ marginTop: "15px", marginBottom: "0px" }}>{deliveryNote.send_branch_name}</p>
            </div>
            <div className="delivery-note-value" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                {intl.formatMessage({
                  id: "113568",
                  defaultMessage: "Destination Branch",
                })} :
              </p>
              <div>
                <p style={{ marginTop: "15px", marginBottom: "0px" }}>{deliveryNote.received_branch_name}</p>
              </div>
            </div>
            <div className="delivery-note-value" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                {intl.formatMessage({
                  id: "113169",
                  defaultMessage: "Description",
                })}
                :{" "}
              </p>
              <div>
                <p style={{ marginTop: "15px", marginBottom: "0px" }}>{deliveryNote.description}</p>
              </div>
            </div>
            <div className="delivery-note-value" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                {intl.formatMessage({
                  id: "113570",
                  defaultMessage: "Status",
                })} :
              </p>
              <div>
                <p style={{ marginTop: "15px", marginBottom: "0px" }}>{status}</p>
              </div>
            </div>
            <div className="delivery-note-value" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                {intl.formatMessage({
                  id: "113571",
                  defaultMessage: "Created At",
                })} :
              </p>
              <div>
                <p style={{ marginTop: "15px", marginBottom: "0px" }}>{new Date(deliveryNote.order_date).toLocaleString()}</p>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="container">
            <BootstrapTable
              keyField="item_id"
              data={deliveryNote.deliveryNoteDetail}
              columns={columns}
              wrapperClasses="table-responsive"
              bordered={false}
              classes="table table-head-custom table-vertical-center overflow-hidden"
              bootstrap4
            />
          </div>
          {isMainBranch != 0 && roleList.includes('CREATE') && roleList.includes('EDIT') && (
          <div className="delivery-note-value" style={{ marginTop: "25px", display: "flex", justifyContent: "flex-end" }}>
              <>
                {(deliveryNote.status === 'PENDING' && isMainBranch != 0) && (
                  <div>
                    <button
                      type="button"
                      className="btn btn-primary mr-2"
                      onClick={() => handleVerify("confirm")}
                    >
                      {intl.formatMessage({ id: "113573", defaultMessage: "Accept" })}
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => handleVerify("cancel")}
                      disabled={deliveryNote.status === 'COMPLETED' || deliveryNote.status === 'CANCELED' || isMainBranch == 0}
                    >
                      {intl.formatMessage({ id: "113574", defaultMessage: "Decline" })}
                    </button>
                  </div>
                )}
              </>
          </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};